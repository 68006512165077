// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    flex-wrap: wrap;
    margin: 100px auto 20px auto;
}
.card-num {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
 
  transform:translateY(40px);
  }
  
  .profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .rectangle {
    background-color: #ffffff;
    width: 260px;
    padding-block: 40px;
    border-radius: 10px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */

  }
  
  .rectangle h2 {
    font-size: 24px;
    margin-bottom: 6px;
    text-align: center;
    margin-top: 8px;
  }
  
  .rectangle p {
    font-size: 16px;
    color: #555;
    text-align: center;
  }
  @media only screen and (max-width: 500px) {
    .cards{
      
      margin: 0px auto ;
  }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Card.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,eAAe;IACf,4BAA4B;AAChC;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;;EAEpB,0BAA0B;EAC1B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,8CAA8C;;EAEhD;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,kBAAkB;EACpB;EACA;IACE;;MAEE,iBAAiB;EACrB;EACA","sourcesContent":[".cards{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 75%;\n    flex-wrap: wrap;\n    margin: 100px auto 20px auto;\n}\n.card-num {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n  }\n  \n  .circle {\n    width: 60px;\n    height: 60px;\n    border-radius: 50%;\n \n  transform:translateY(40px);\n  }\n  \n  .profile-pic {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n  \n  .rectangle {\n    background-color: #ffffff;\n    width: 260px;\n    padding-block: 40px;\n    border-radius: 10px;\n    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */\n\n  }\n  \n  .rectangle h2 {\n    font-size: 24px;\n    margin-bottom: 6px;\n    text-align: center;\n    margin-top: 8px;\n  }\n  \n  .rectangle p {\n    font-size: 16px;\n    color: #555;\n    text-align: center;\n  }\n  @media only screen and (max-width: 500px) {\n    .cards{\n      \n      margin: 0px auto ;\n  }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
