// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Testimonial_div{
    margin: "3.5rem auto";
}
.btn2{
    color: black;
    border: 1.5px solid black;
}`, "",{"version":3,"sources":["webpack://./src/pages/Course.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".Testimonial_div{\n    margin: \"3.5rem auto\";\n}\n.btn2{\n    color: black;\n    border: 1.5px solid black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
