import React, { useState, useEffect } from "react";
import Bg_about from "../assets/landingPageBg.jpg";
import { Box, Typography, Button } from "@mui/material";
import "./sellOnONDC.css";
import "./Card.css";
// import SellOnONDCForm from "../components/forms/SellOnONDCform";
// import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
// import PaymentsIcon from "@mui/icons-material/Payments";
// import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import TestimonialCarousel from "../components/TestimonialCarousel";
import toggle_btn from "../assets/toggle_btn.png";
import banner_ondc from "../assets/BANNERondc.png";
import { useNavigate } from "react-router";
import banner_img from "../assets/banner_img.webp";
import frontViewStep0 from "../assets/Step0.webp";
import frontViewStep1 from "../assets/Step1.webp";
import frontViewStep2 from "../assets/Step2.webp";
import frontViewStep3 from "../assets/Step3.webp";
import frontViewStep4 from "../assets/Step4.webp";
import frontViewStep5 from "../assets/Step5.webp";
import frontViewStep6 from "../assets/Step6.webp";
import frontViewStep7 from "../assets/Step7.webp";
import { useTranslation } from "react-i18next";
import empowerment from "../assets/empowerment.webp";
import collaboration from "../assets/collaboration.webp";
import Featureicon1 from "../assets/featureIcon1.webp";
import Featureicon2 from "../assets/featureIcon2.webp";
function SellOnONDC() {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();
  const handleStepClick = (step) => {
    setActiveStep(step === activeStep ? "0" : step);
  };
  const renderAccordionStep = (step, summaryKey, bodyKey) => (
    <div
      key={step}
      className={`accordion-item ${activeStep === step ? "active" : ""}`}
    >
      <div className="accordion-summary" onClick={() => handleStepClick(step)}>
        <span className="accordion-text">{t(summaryKey)}</span>
        <span
          className={`accordion-icon ${activeStep === step ? "rotate" : ""}`}
        >
          <img src={toggle_btn} alt="" style={{ width: "20px" }} />
        </span>
      </div>
      {activeStep === step && (
        <div className="accordion-body">{t(bodyKey)}</div>
      )}
    </div>
  );
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const totalSteps = 7;

  const stepImages = [
    frontViewStep0,
    frontViewStep1,
    frontViewStep2,
    frontViewStep3,
    frontViewStep4,
    frontViewStep5,
    frontViewStep6,
    frontViewStep7,
  ];
  return (
    <div className="sellonondc">
      <div className="landingPageCover">
        <img
          src={Bg_about}
          width="100%"
          height="100vh"
          alt="landing"
          className="landingPageCover__animation"
        />
      </div>
      <div className="main-container" data-aos="fade-up">
        <div
          className="banner"
          style={{
            justifyContent: "center",
            alignItems: "center",

            width: "100%",
          }}
        >
          <div className="bannerTextONDC">
            <h1>
              <span style={{ color: "black" }}>Sell </span>Your Beautiful
              Creations
              <span style={{ color: "black" }}> Across</span> India
            </h1>
            <p
              style={{ textAlign: "justify", width: "100%", fontSize: "18px" }}
            >
              UdyamWell seamlessly connects you with ONDC network, opening doors
              to millions of potential customers across India.
            </p>
            <div className="homeButtons">
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  padding: "10px 60px",
                  font: "Poppins,sans-serif",
                  fontWeight: "500",
                  textTransform: "inherit",
                  border: "none",
                  background:
                    "linear-gradient(90deg, rgba(46,132,70,1) 0%, rgba(0,100,0,1) 100%);",
                }}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.udyamwell.seller.app&hl=en_US",
                    "_blank"
                  );
                }}
              >
                UdyamWell - ONDC Seller
              </Button>
              <Button
                sx={{
                  p: "10px 20px",
                  borderRadius: "2px",
                  border: "solid 1.5px",
                  ml: 3,
                  font: "Poppins,sans-serif",
                  fontWeight: "500",
                  color: "#006400",
                  textTransform: "initial",
                  transition:
                    "filter 0.4s ease, color 0.4s ease, background 0.3s ease",
                  "&:hover": {
                    filter: "drop-shadow(-2px 4px 4px   #0064003a  )",
                    color: "white",
                    background: "linear-gradient( #2E8446 , #006400 )",
                  },
                }}
                variant="outlined"
                onClick={() => navigate("/sell-on-ondc-form")}
              >
                Need Help?
              </Button>
            </div>
          </div>
          <div className="bannerImg">
            <img src={banner_img} alt="" />
          </div>
        </div>
      </div>
      <div alignItems={"center"}>
        <Box
          sx={{ p: "0 55px" }}
          textAlign={"center"}
          width={"auto"}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
          justifyContent={"center"}
          alignItems={"center"}
          data-aos="fade-up"
        >
          <h1 className="Text_Ondch1">
            <span style={{ color: "black" }}>Learn about </span> ONDC
          </h1>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",

              fontSize: "18px",
              textAlign: "jcenter",
              width: "80%",
              transform: "translateY(-20px)",
            }}
            className="serviceSubHeading1"
          >
            {" "}
            Empowering Rural Dreams with Udyamwell
          </Typography>
          <hr
            style={{
              width: "80%",
              height: "3px",
              background: "#046A3A",
              border: "none",
            }}
          />
          <Typography
            variant="h6"
            fontFamily={"Poppins,sans-serif"}
            fontWeight={"500"}
            fontSize={"18px"}
            className="serviceSubHeading"
          >
            ONDC (Open Network for Digital Commerce) is a revolutionary network
            seamlessly uniting potential sellers and buyers via an
            interconnected forum fostering collaborative and secured
            transactions.
          </Typography>
          <Typography
            variant="h6"
            fontFamily={"Poppins,sans-serif"}
            fontWeight={"500"}
            fontSize={"18px"}
            className="serviceSubHeading"
          >
            At the root level, ONDC addresses the issues faced in traditional
            marketplaces which include but are not limited to lack of
            transparency, higher costs and unsatisfactory interoperability.
            Utilizing the potential of blockchain technology and the Beckn
            protocol, ONDC establishes a secure network that documents
            transactions while maintaining transparency and efficient
            verifiability.
          </Typography>
          <Typography
            variant="h6"
            fontFamily={"Poppins,sans-serif"}
            fontWeight={"500"}
            fontSize={"18px"}
            className="serviceSubHeading"
          >
            ONDC is like a giant marketplace where buyers and sellers can
            connect easily, without any middlemen. This means more control for
            you and potentially higher profits.
          </Typography>
        </Box>

        {/* <Box
          sx={{ p: "0 55px" }}
          textAlign={"center"}
          width={"auto"}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
          justifyContent={"center"}
          alignItems={"center"}
          marginTop={"80px"}
          data-aos="fade-up"
        >
          {" "} */}
        {/* <Typography
            variant="h3"
            fontFamily={"Poppins,sans-serif"}
            fontSize={"50px"}
            fontWeight={"600"}
          >
            <span style={{ color: "#046A3A", marginTop: "40px" }}>
              Choose Value,{" "}
            </span>{" "}
            Choose ONDC!
          </Typography>
          <Typography
            variant="h6"
            fontFamily={"Poppins,sans-serif"}
            fontWeight={"500"}
            fontSize={"18px"}
            className="serviceSubHeading"
          >
            ONDC supports a transparent and decentralised system specifically
            tailored for digital commerce. With ONDC, secure access, management
            and the potential monetization of your valuable data are right at
            your fingertips! Thus, ONDC empowers and grants you a strong footing
            in the digital marketplace.
          </Typography> */}

        {/* <Box className="countBox1" width={"80%"}>
            <Box className="count1">
              <PeopleAltOutlinedIcon sx={{ fontSize: "50px" }} />{" "}
              <Typography
                variant="h5"
                sx={{ ml: 2, color: "#236836", fontWeight: 600 }}
              >
                Expanded customer choice
              </Typography>
            </Box>
            <Box className="count1">
              <LaptopMacIcon sx={{ fontSize: "50px" }} />{" "}
              <Typography
                variant="h5"
                sx={{ ml: 2, color: "#236836", fontWeight: 600 }}
              >
                Catalyzes innovation
              </Typography>
            </Box>
            <Box className="count1">
              <PaymentsIcon sx={{ fontSize: "50px" }} />{" "}
              <Typography
                variant="h5"
                sx={{ ml: 2, color: "#236836", fontWeight: 600 }}
              >
                Cost efficiency
              </Typography>
            </Box>
          </Box> */}
        {/* </Box> */}
        <div
          style={{
            background: "#E6F0E8",
            paddingBlock: "120px",
            marginBlock: "100px",
          }}
        >
          <Box
            sx={{ p: "0 55px" }}
            textAlign={"center"}
            width={"auto"}
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
            justifyContent={"center"}
            alignItems={"center"}
            data-aos="fade-up"
          >
            <h1 className="Text_Ondch1">
              <span style={{ color: "black" }}>UdyamWell </span>- Your
              ‘must-have’ success partner!
            </h1>

            <Typography
              variant="h6"
              fontFamily={"Poppins,sans-serif"}
              fontWeight={"500"}
              fontSize={"18px"}
              className="serviceSubHeading"
            >
              UdyamWell strives to support and empower the ambitions and dreams
              born in rural regions. Our ability to analyze data will definitely
              help in aiding e-commerce operations, foster growth in the digital
              environment and boost sales performances. With unmatched security,
              full-time support and the ability to personalize the services, you
              are sure to experience immense gains!
            </Typography>
          </Box>
          <Box
            sx={{ p: "0 55px" }}
            textAlign={"center"}
            width={"auto"}
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
            justifyContent={"center"}
            alignItems={"center"}
            data-aos="fade-up"
          >
            <img
              src={banner_ondc}
              style={{
                width: "50%",
                marginTop: "20px",
              }}
              alt="ondc"
            />
          </Box>
          <div className="cards" data-aos="fade-down">
            <div className="card-num">
              <div className="circle">
                <img src={empowerment} alt="Profile" className="profile-pic" />
              </div>
              <div className="rectangle">
                <h2>250+</h2>
                <p>Entreprenurs Trained</p>
              </div>
            </div>
            <div className="card-num">
              <div className="circle">
                <img
                  src={collaboration}
                  alt="Profile"
                  className="profile-pic"
                />
              </div>
              <div className="rectangle">
                <h2>100+</h2>
                <p>Enterprises Onboarded</p>
              </div>
            </div>
            <div className="card-num">
              <div className="circle">
                <img src={Featureicon1} alt="Profile" className="profile-pic" />
              </div>
              <div className="rectangle">
                <h2>3</h2>
                <p>Major Categories</p>
              </div>
            </div>
            <div className="card-num">
              <div className="circle">
                <img src={Featureicon2} alt="Profile" className="profile-pic" />
              </div>
              <div className="rectangle">
                <h2>20+</h2>
                <p>Onboarding LinedUp</p>
              </div>
            </div>
          </div>
        </div>
        <Box
          className="registerDownload"
          sx={{
            p: "60px 55px 0px 55px",
            margin: "20px auto",
            width: "100%",
            "@media (max-width: 500px)": {
              p: "0px 0px 0px 0px",
            },
          }}
          textAlign={"center"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          data-aos="fade-down"
        >
          <h1 className="Text_Ondch1" style={{ width: "80%" }}>
            {t("appinf_head1")}
            <span style={{ color: "black" }}> {t("appinf_head2")}</span>{" "}
            {t("appinf_head3")}{" "}
            <span style={{ color: "black" }}> {t("appinf_head4")}</span>{" "}
            {t("appinf_head5")}
          </h1>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              mt: 2,
              fontSize: "18px",
              textAlign: "justify",
              width: "80%",
              "@media (max-width: 500px)": {
                width: "80%",
              },
            }}
            className="serviceSubHeading1"
          >
            {" "}
            {t("appinf_body")}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontWeight: "500",
              mt: 2,
              fontSize: "18px",
              textAlign: "justify",
              width: "80%",
              "@media (max-width: 500px)": {
                width: "80%",
              },
            }}
            className="serviceSubHeading1"
          >
            {" "}
            Here's a step-by-step guide on how to register and get started on
            our platform:
          </Typography>
        </Box>
        <Box sx={{ m: "0 auto" }}>
          <div className="banner" style={{ margin: "0 auto", width: "100%" }}>
            <div className="bannerImg" data-aos="fade-up">
              <img
                src={stepImages[activeStep]}
                alt=""
                style={{ maxWidth: "100%", height: "auto", width: "100%" }}
              />
            </div>
            <div className="bannerText" style={{ marginRight: "5%" }}>
              <div className="custom-accordion" data-aos="fade-up">
                <div className="accordion-counter-top">
                  <span style={{ fontWeight: "normal" }}>Step</span>
                  {` ${activeStep}/${totalSteps}`}
                </div>
                {renderAccordionStep(1, "appinf_step1", "appinf_stepbody1")}
                {renderAccordionStep(2, "appinf_step2", "appinf_stepbody2")}
                {renderAccordionStep(3, "appinf_step3", "appinf_stepbody3")}
                {renderAccordionStep(4, "appinf_step4", "appinf_stepbody4")}
                {renderAccordionStep(5, "appinf_step5", "appinf_stepbody5")}
                {renderAccordionStep(6, "appinf_step6", "appinf_stepbody6")}
                {renderAccordionStep(7, "appinf_step7", "appinf_stepbody7")}
              </div>
            </div>
          </div>
        </Box>
        <Box
          sx={{ p: "0 55px" }}
          textAlign={"center"}
          width={"auto"}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
          justifyContent={"center"}
          alignItems={"center"}
          marginTop={"80px"}
        >
          <Box className="testimonials-head" data-aos="fade-up">
            <h1 className="Text_Ondch1 lefthead" style={{ textAlign: "left" }}>
              {" "}
              They talk about it
              <span style={{ color: "black" }}> Better</span> than us
            </h1>
            {/* <p className="Text_Ondcp" style={{ textAlign: "justify" }}>
              Discover the success stories and firsthand experiences of our
              diverse community of sellers on UdyamWell. Each testimonial
              provides a unique perspective on how our platform, in conjunction
              with ONDC, has empowered businesses to grow, reach wider markets,
              and achieve remarkable sales growth.
            </p> */}
            <div style={{ width: "100%" }} data-aos="fade-down">
              <TestimonialCarousel />
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            p: { xs: "0", sm: "0 55px" },
          }}
          textAlign={"center"}
          width={"auto"}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
          justifyContent={"center"}
          alignItems={"center"}
          marginTop={"120px"}
          marginBottom={"100px"}
          data-aos="fade-down"
        >
          <h1 className="Text_Ondch1">
            Frequently
            <span style={{ color: "Black" }}> Asked</span> Questions
          </h1>
          <div className="custom-accordion">
            {renderAccordionStep(
              1,
              "Facing Issues While Registering on the UdyamWell App? Here's What to Do.",
              "Fill out our quick support form and we will reach out to answer your questions and get you back on track"
            )}
            {renderAccordionStep(
              2,
              "Is there any payment required for this procedure?",
              "No payment is required to make use of ONDC. A success-based commission is charged on sales. Free onboarding and budget-friendly training programs are provided."
            )}
            {renderAccordionStep(
              3,
              "Is UdyamWell secure?",
              "UdyamWell offers a highly secure and well maintained environment. A specialized team works efficiently to ensure that no harm comes to any user."
            )}
            {renderAccordionStep(
              4,
              "What does ONDC offer?",
              "ONDC is an open source service network that brings together several vendors under a common roof. It makes sure that there is an impartial and fair deal for all buyers, sellers and partners."
            )}
            {renderAccordionStep(
              5,
              "How can I learn more about this program?",
              "You can learn more about this initiative from our UdyamSheel page or you can connect with one of our team members by filling out the registration form."
            )}
          </div>
        </Box>
      </div>
    </div>
  );
}

export default SellOnONDC;
