import React, { useState, useEffect } from "react";
import Bg_login from "../assets/Bg_login.png";
import { useSelector } from "react-redux";
import CustomTable from "../components/CustomTable";
import CustomTable2 from "../components/CustomTable2";
import CustomTable3 from "../components/CustomTable3";
import { Button } from "@mui/material";
import axios from "axios";
import "./UdyamDashboard.css";
import Swal from "sweetalert2";

function UdyamDashboard() {
  const { user } = useSelector((state) => state.user);
  const currentTime = new Date();
  const hours = currentTime.getHours();

  let greeting;

  if (hours < 12) {
    greeting = "Good Morning";
  } else if (hours < 16) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  const heading2 = "Campusprenuer";
  const columns = ["Task Code", "Title", "Deadline"];
  const columns3 = [
    "Task Code",
    "Submitted File",
    "Accomplished ",
    "Pts Earned",
  ];
  const columns2 = ["Rank", "Name", "Total Pts"];
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [rank, setRank] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const id = user._id;
      try {
        const response = await axios.get(
          `https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/get-task-by-id/${id}`
        );
        const taskData = response.data.task.map((task) => ({
          taskId: task.taskId,
          taskName: task.taskName,
          deadline: new Date(task.deadline),
        }));

        // Sort taskData by deadline in ascending order
        taskData.sort((a, b) => a.deadline - b.deadline);

        // Convert deadline back to string format for display
        const formattedTaskData = taskData.map((task) => [
          task.taskId,
          task.taskName,
          task.deadline.toLocaleDateString(),
        ]);

        setData(formattedTaskData);
      } catch (error) {
        console.error("Error fetching task data:", error);
      }
    };

    fetchData();
  }, [user._id]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const customStyle = {
    borderRadius: "20px",
    border: "1px solid green",
    padding: "10px",
    backgroundColor: "#ffffff",
    width: windowWidth > 900 ? "53vw" : "90vw",
    // height: "30vh",
    boxShadow: "0px 2px 20px #046A3A90",
  };
  const customStyle2 = {
    borderRadius: "20px",
    padding: "30px",
    backgroundColor: "#ffffff",
    width: windowWidth > 900 ? "23vw" : "90vw",
    height: "549px",
    boxShadow: "0px 2px 20px #046A3A90",
    border: "1px solid green",
  };

  const [taskId, setTaskId] = useState("");
  const [taskfile, setFile] = useState("");

  const handleTaskIdChange = (e) => {
    setTaskId(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!taskId || !taskfile) {
      console.log("Invalid task ID or file");
      return;
    }

    const formData = new FormData();
    formData.append("taskId", taskId);
    formData.append("_id", user._id); // Assuming user._id represents the user's ID
    formData.append("taskfile", taskfile);

    try {
      const response = await axios.patch(
        "https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/upload-task",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type for FormData
          },
        }
      );

      console.log(response.data);
      if (response.status === 200) {
        Swal.fire("Task Uploaded Successfully");
      }

      setTaskId("");
      setFile("");
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.get(
          "https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/get-leaderboard"
        );

        const leaderboardData = response.data.map((user) => ({
          _id: user._id,
          name: user.name,
          totalMarks: user.totalMarks,
        }));

        leaderboardData.sort((a, b) => b.totalMarks - a.totalMarks);

        leaderboardData.forEach((user, index) => {
          user.rank = index + 1;
        });

        const userIndex = leaderboardData.findIndex((u) => u._id === user._id);

        const userRank = userIndex !== -1 ? userIndex + 1 : null;

        setData2(leaderboardData);

        setRank(userRank);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    };

    fetchData2();
  }, [user._id]);

  const fetchPdf = async (taskId, userId) => {
    console.log("Fetch pdf hit ==>");
    try {
      const response = await fetch(
        `https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/get-file/${taskId}/${userId}`
      );
      if (response.ok) {
        const blob = await response.blob();
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl); // Open PDF in a new tab
      } else {
        console.error("Failed to fetch PDF:", response.status);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  useEffect(() => {
    const fetchData3 = async () => {
      try {
        const response = await axios.get(
          `https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/get-task-by-id/${user._id}`
        );
        console.log("response:", response);
        const tasks = response.data.task;

        // Array to store all task details
        const allTaskDetails = [];

        // Iterate over each task
        for (const task of tasks) {
          const taskId = task.taskId;

          // Check if submissions array is not empty
          if (task.submissions && task.submissions.length > 0) {
            // Iterate over each submission in the task
            for (const submission of task.submissions) {
              // Ensure submission object is not null
              if (submission !== null) {
                const userId = submission.udyamsathi;
                console.log(taskId, userId);

                // Additional details such as accomplished status and marks
                const accomplished = task.submissions.some(
                  (sub) => sub && sub.udyamsathi === userId
                );
                let marks = 0;

                // Find the marks for the user from the task's marks array
                const userMarks = task.marks.find(
                  (mark) => mark && mark.udyamsathi === userId
                );
                if (userMarks) {
                  marks = userMarks.marks;
                }

                // Construct task detail array for each submission
                const taskDetail = [
                  taskId,
                  <div>
                    <Button
                      sx={{
                        p: "4px 20px",
                        height: "90%",
                        borderRadius: "10px",
                        border: "solid 1.5px",
                        font: "Poppins,sans-serif",
                        fontWeight: "500",
                        color: "#006400",
                        textTransform: "initial",
                        transition:
                          "filter 0.4s ease, color 0.4s ease, background 0.3s ease",
                        "&:hover": {
                          filter: "drop-shadow(-2px 4px 4px   #0064003a  )",
                          color: "white",
                          background: "linear-gradient( #2E8446 , #006400 )",
                        },
                      }}
                      onClick={() => fetchPdf(taskId, userId)}
                    >
                      View
                    </Button>
                  </div>,
                  accomplished ? "Yes" : "No",
                  marks, // Include marks in the task detail array
                ];

                console.log("task details", taskDetail);

                // Push task details for each submission to the array
                allTaskDetails.push(taskDetail);
              }
            }
          } else {
            // Handle case when submissions array is empty
            console.log(`No submissions found for task ${taskId}`);
          }
        }

        // Set state with all task details
        setData3(allTaskDetails);
      } catch (error) {
        console.error("Error fetching detailed report data:", error);
      }
    };

    fetchData3();
  }, [user._id]);

  return (
    <>
      <div className="landingPageCover">
        <img
          src={Bg_login}
          height="100vh"
          alt="landing"
          className="landingPageCover__animation"
        />
      </div>
      <div className="block1">
        <div className="subblock1">
          <div className="block-head">
            <h1 style={{ width: "70%", fontSize: "32px" }}>
              {greeting},{" "}
              <span style={{ color: "green" }}>
                {user ? user.name : "Campuspreneur"}
              </span>
            </h1>
            <Button
              variant="contained"
              sx={{
                color: "white",
                padding: "10px 20px",
                borderRadius: "10px",
                fontWeight: "400",
                textTransform: "inherit",
                border: "none",
                width: "20%",
                height: "90%",
                margin: "auto auto",
                background:
                  "linear-gradient(90deg, rgba(46,132,70,1) 0%, rgba(0,100,0,1) 100%);",
              }}
              onClick={() => setIsOpen(true)} // Open the modal on button click
            >
              Submit Task
            </Button>
          </div>
          <div className="table1" style={{ transform: "translateY(-20px)" }}>
            <CustomTable2
              heading="Task"
              heading2="Notification"
              columns={columns}
              data={data}
              style={customStyle}
            />
          </div>
          <div className="table2" style={{ transform: "translateY(-20px)" }}>
            <CustomTable3
              heading="Detailed"
              heading2="Report"
              columns={columns3}
              data={data3}
              style={customStyle}
            />
          </div>
        </div>
        <div className="subblock2">
          <div className="table1">
            <CustomTable
              heading={heading2}
              columns={columns2}
              data={data2}
              rank={rank}
              style={customStyle2}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsOpen(false)}>
              &times;
            </span>
            <h2>Submit Task</h2>
            <form onSubmit={handleSubmit}>
              <div className="Udyaminput">
                <input
                  id="taskId"
                  name="taskId"
                  type="text"
                  placeholder="Enter Task Code"
                  value={taskId}
                  onChange={handleTaskIdChange}
                  required
                />
                <input
                  id="fileInput"
                  name="fileInput"
                  type="file"
                  onChange={handleFileChange}
                  required
                />
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default UdyamDashboard;
