// import React, { useEffect, useState } from "react";
// import "./about.css";
// import { Box, Typography, Button } from "@mui/material";
// import { useTranslation } from "react-i18next";
// import market from "../../src/assets/market.webp";
// import collaboration from "../assets/collaboration.webp";
// import empowerment from "../../src/assets/empowerment.webp";
// import Carousel from "../components/Carousel";
// import Bg_about from "../../src/assets/Bg_about.webp";
// import { useNavigate } from "react-router";
// import founder from "../assets/founder.webp";
// import article2 from "../assets/article2.webp";
// import article1 from "../assets/article1.webp";
// // import news1 from "../assets/news1.jpg";
// import "./Card2.css";
// import "./TwoBoxes.css";
// import TestimonialCarousel from "../components/TestimonialCarousel";
// import JourneyMap from "../components/styles/JourneyMap";
// import { Facebook, Twitter, Instagram } from "@mui/icons-material";
// import ondc from "../assets/ondc.webp";
// import sbi from "../assets/sbifoundationlogo.webp";
// import microsoft from "../assets/microsoft.webp";
// import iitmandicatalyst from "../assets/iitmandicatalyst.webp";
// import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
// import PaymentsIcon from "@mui/icons-material/Payments";
// import LaptopMacIcon from "@mui/icons-material/LaptopMac";
// import { margin } from "@mui/system";
// const TwoBoxes = () => {
//   // Sample data for the right boxes
//   const rightBoxesData = [
//     {
//       id: 1,
//       image: article1,
//       heading:
//         "देश में अनुसंधान और नवाचार के मामले हिमाचल में सबसे आगे : गोकुल बुटेल",
//     },
//     {
//       id: 2,
//       image: article2,
//       heading: "How The Country's Largest Bank Is Supporting Social Startups",
//     },
//     // Add more right box data as needed
//   ];

//   // Sample data for the selected box
//   const [selectedBox, setSelectedBox] = useState(rightBoxesData[0]);

//   // Function to handle click on a right box
//   const handleRightBoxClick = (boxId) => {
//     const selectedBoxData = rightBoxesData.find((box) => box.id === boxId);
//     setSelectedBox(selectedBoxData);
//   };

//   return (
//     <div className="two-boxes-container">
//       <div className="selected-box">
//         <img src={selectedBox.image} alt={""} />
//         <h2>{selectedBox.heading}</h2>
//       </div>

//       <div className="right-boxes">
//         {rightBoxesData.map((box) => (
//           <div
//             key={box.id}
//             className="right-box"
//             onClick={() => handleRightBoxClick(box.id)}
//           >
//             <img src={box.image} alt={""} />
//             <h3 style={{ fontSize: "18px" }}>{box.heading}</h3>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const AboutCard = ({ heading, text1, text2, lists, icon }) => {
//   return (
//     <div className="cardContainer2" style={{ height: "550px" }}>
//       <span>
//         <img src={icon} alt="" />
//       </span>
//       <h4 style={{ width: "90%", marginInline: "auto" }}>{heading}</h4>
//       <p
//         style={{
//           textAlign: "justify",
//           color: "black",
//           fontWeight: "200",
//           fontSize: "18px",
//         }}
//       >
//         {text1}
//       </p>
//     </div>
//   );
// };

// // const Checkpoint = ({ cx, cy, heading, description }) => {
// //   const [isOpen, setIsOpen] = useState(false);

// //   const handleHoverEnter = () => {
// //     setIsOpen(true);
// //   };

// //   const handleHoverLeave = () => {
// //     setIsOpen(false);
// //   };

// //   return (
// //     <g>
// //       <circle
// //         cx={cx}
// //         cy={cy}
// //         r="24"
// //         fill="#046A3A"
// //         onMouseEnter={handleHoverEnter}
// //         onMouseLeave={handleHoverLeave}
// //         style={{ cursor: "pointer" }}
// //       />
// //       <text
// //         x={cx}
// //         y={cy - 30}
// //         textAnchor="middle"
// //         fontSize="18"
// //         fontWeight="bold"
// //         fill="black"
// //       >
// //         {heading}
// //       </text>
// //       {isOpen && (
// //         <>
// //           <foreignObject x={cx - 65} y={cy + 40} width="140" height="600">
// //             <div
// //               style={{
// //                 background: "#046A3A",
// //                 padding: "4px",
// //                 color: "white",
// //                 borderRadius: "10px",
// //               }}
// //             >
// //               <p style={{ textAlign: "justify" }}>{description}</p>
// //             </div>
// //           </foreignObject>
// //         </>
// //       )}
// //     </g>
// //   );
// // };
// // const Checkpoint2 = ({ cx, cy, heading, description }) => {
// //   const [isOpen, setIsOpen] = useState(false);

// //   const handleHoverEnter = () => {
// //     setIsOpen(true);
// //   };

// //   const handleHoverLeave = () => {
// //     setIsOpen(false);
// //   };

// //   return (
// //     <g>
// //       <circle
// //         cx={cx}
// //         cy={cy}
// //         r="8"
// //         fill="#046A3A"
// //         onMouseEnter={handleHoverEnter}
// //         onMouseLeave={handleHoverLeave}
// //         style={{ cursor: "pointer" }}
// //       />
// //       <text
// //         x={cx + 20}
// //         y={cy}
// //         textAnchor="start"
// //         fontSize="12"
// //         fontWeight="bold"
// //         fill="black"
// //       >
// //         {heading}
// //       </text>
// //       {isOpen && (
// //         <>
// //           <foreignObject x={cx + 10} y={cy + 2} width="80" height="100">
// //             <div>
// //               <p
// //                 style={{
// //                   background: "#046A3A",
// //                   color: "white",
// //                   fontSize: "8px",
// //                   padding: "4px",
// //                 }}
// //               >
// //                 {description}
// //               </p>
// //             </div>
// //           </foreignObject>
// //         </>
// //       )}
// //     </g>
// //   );
// // };

// // const Boxs = ({ image, imageUrl, width }) => {
// //   const handleLinkClick = (e) => {
// //     e.preventDefault();
// //     // Open pop-up window with image
// //     window.open(imageUrl, "_blank", "width=80%,height=auto");
// //   };
// //   return (
// //     <Link to="#" style={{ textDecoration: "none" }} onClick={handleLinkClick}>
// //       <img
// //         src={image}
// //         alt="B"
// //         style={{
// //           width: "400px",
// //           height: "auto",
// //           transition: "transform 0.3s",
// //         }}
// //       />
// //     </Link>
// //   );
// // };
// export const AboutServices = () => {
//   const { t } = useTranslation();
//   useEffect(() => {
//     window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
//   }, []);
//   const navigate = useNavigate();
//   const handelClick = () => {
//     navigate("/services");
//   };
//   return (
//     <div className="aboutServices" style={{ marginTop: "5rem" }}>
//       <div className="aboutus-box">
//         <h3>
//           <span style={{ color: "#000000" }}>{t("our")}</span> {t("services")}
//         </h3>
//         <Typography
//           variant="h6"
//           fontWeight={"500"}
//           fontSize={"16px"}
//           className="serviceSubHeading"
//           textAlign={"justify"}
//           marginTop={"1rem"}
//           color={"black"}
//           width={"100%"}
//           fontFamily={"Poppins, sans-serif"}
//         >
//           {" "}
//           {t("aboutbody_home")}
//         </Typography>
//         <div className="homeAboutButton">
//           <button
//             style={{ cursor: "pointer", borderRadius: "10px" }}
//             onClick={handelClick}
//           >
//             {t("readmore_btn")}
//           </button>
//         </div>
//       </div>

//       <AboutCard
//         heading={t("home_aboutcardheading1")}
//         icon={empowerment}
//         text1={t("home_aboutcardbody1")}
//       />
//       <AboutCard
//         icon={collaboration}
//         heading={t("home_aboutcardheading2")}
//         text1={t("home_aboutcardbody2")}
//       />
//       <AboutCard
//         icon={market}
//         heading={t("home_aboutcardheading3")}
//         text1={t("home_aboutcardbody3")}
//       />
//     </div>
//   );
// };

// const About = () => {
//   useEffect(() => {
//     window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
//   }, []);
//   return (
//     <>
//       <div className="landingPageCover">
//         <img
//           src={Bg_about}
//           width="100vw"
//           alt="landing"
//           className="landingPageCover__animation"
//         />
//       </div>
//       <Box sx={{ mt: 20, textAlign: "center" }}>
//         <Typography
//           variant="h1"
//           sx={{
//             color: "#046A3A",
//             fontFamily: "Poppins,sans-serif",
//             fontWeight: 600,
//             fontSize: "50px",
//           }}
//         >
//           Empowering <span style={{ color: "black" }}>Rural Communities </span>:
//           Our Story
//         </Typography>
//         <Typography
//           className="aboutText"
//           sx={{
//             textAlign: "center",
//             margin: "10px auto",
//             width: "70%",
//             fontWeight: "500",
//             fontFamily: "Poppins,sans-serif",
//             padding: "0 20px",
//             fontSize: "18px",
//           }}
//           variant="h6"
//         >
//           A spirit of transition is present in our country. From villages to
//           cities, sparks of ideas connect us together. <br />
//           We at UdyamWell are passionate about helping rural communities in
//           consolidating their ideas.
//         </Typography>
//       </Box>

//       <Carousel />
//       <Box className="countBox1" width={"70%"}>
//         <Box className="count1">
//           <LaptopMacIcon sx={{ fontSize: "50px" }} />{" "}
//           <Typography
//             variant="h5"
//             sx={{ ml: 2, color: "#236836", fontWeight: 600 }}
//           >
//             5 Courses
//           </Typography>
//         </Box>
//         <Box className="count1">
//           <PeopleAltOutlinedIcon sx={{ fontSize: "50px" }} />{" "}
//           <Typography
//             variant="h5"
//             sx={{ ml: 2, color: "#236836", fontWeight: 600 }}
//           >
//             10+ Mentors
//           </Typography>
//         </Box>
//         <Box className="count1">
//           <PaymentsIcon sx={{ fontSize: "50px" }} />{" "}
//           <Typography
//             variant="h5"
//             sx={{ ml: 2, color: "#236836", fontWeight: 600 }}
//           >
//             100+ Business
//           </Typography>
//         </Box>
//       </Box>
//       <div className="HomeContainer" style={{ margin: "0rem auto 0 auto" }}>
//         <div className="content_heading_udyamSathi" data-aos="fade-down">
//           <h1 className=" bannerText_Campush1">
//             {" "}
//             <span style={{ color: "black" }}>Unleash The Power Of </span>{" "}
//             Campuspreneur{" "}
//           </h1>
//           <h4 style={{ color: "black", fontWeight: "400" }}>
//             Empowering Rural Dreams with Udyamwell
//           </h4>
//         </div>

//         <hr
//           className="horizontal"
//           style={{ margin: "0px auto", width: "70%" }}
//         />

//         <p style={{ width: "70%", margin: "30px auto" }}>
//           <span style={{ fontWeight: "bold" }}>
//             A One-Stop Solution for Rural Entreprises!
//           </span>{" "}
//           <br />
//           It aims to empower rural entrepreneurs in India. It provides a
//           marketplace for talented artisans and small businesses to showcase
//           their authentic products, ranging from traditional handicrafts to
//           farm-fresh produce. By supporting local communities and contributing
//           to sustainable growth, UdyamWell strives to create a thriving
//           ecosystem for rural enterprises.
//         </p>

//         <div className="content_udyamsathi">
//           <div className="content_child">
//             <div className="content_grandchild" data-aos="fade-left">
//               <h4
//                 style={{
//                   fontSize: "2rem",
//                   sx: { fontSize: "0.5rem", md: { fontSize: "1rem" } },
//                 }}
//               >
//                 {" "}
//                 <span style={{ color: "black" }}>What services </span> are
//                 offered?{" "}
//               </h4>
//               <p>
//                 UdyamWell provides a comprehensive suite of services aimed at
//                 supporting entrepreneurs. This includes access to expert
//                 mentoring, networking opportunities, workshops on business and
//                 personal wellness, and tailored resources designed to enhance
//                 both entrepreneurial skills and personal well-being.
//               </p>
//             </div>
//             <div className="content_grandchild" data-aos="fade-right">
//               <h4
//                 style={{
//                   fontSize: "2rem",
//                   sx: { fontSize: "0.5rem", md: { fontSize: "1rem" } },
//                 }}
//               >
//                 {" "}
//                 <span style={{ color: "black" }}>How to </span> join?{" "}
//               </h4>
//               <p>
//                 Interested entrepreneurs can join the UdyamWell program by
//                 applying through our website. Simply fill out the application
//                 form with your business details and a brief description of what
//                 you hope to achieve through the program. Our team will review
//                 your application and get in touch with you to discuss the next
//                 steps.
//               </p>
//             </div>
//           </div>
//           <div className="content_child">
//             <div className="content_grandchild" data-aos="fade-left">
//               <h4
//                 style={{
//                   fontSize: "2rem",
//                   sx: { fontSize: "0.5rem", md: { fontSize: "1rem" } },
//                 }}
//               >
//                 {" "}
//                 <span style={{ color: "black" }}>Is there a </span>participation
//                 fee?{" "}
//               </h4>
//               <p>
//                 UdyamWell offers both free and premium services. Basic
//                 membership is free and gives access to a range of resources and
//                 community events. Premium services, including personalized
//                 mentoring and advanced workshops, are available for a fee.
//                 Details and pricing can be found on our website.
//               </p>
//               <p>
//                 <span style={{ fontWeight: "bold" }}></span>
//               </p>
//             </div>
//             <div className="content_grandchild" data-aos="fade-right">
//               <h4
//                 style={{
//                   fontSize: "2rem",
//                   sx: { fontSize: "0.5rem", md: { fontSize: "1rem" } },
//                 }}
//               >
//                 {" "}
//                 <span style={{ color: "black" }}>What makes </span> UdyamWell
//                 unique?{" "}
//               </h4>
//               <p>
//                 UdyamWell stands out due to its dual focus on business growth
//                 and personal wellness. Our holistic approach ensures that
//                 entrepreneurs not only receive guidance on developing their
//                 business but also support in maintaining their mental and
//                 physical health. This balanced support system fosters
//                 sustainable success and a thriving community of well-rounded
//                 entrepreneurs.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Box
//         sx={{
//           paddingBlock: "50px",
//           textAlign: "center",
//           width: "70%",
//           margin: "0 auto",
//         }}
//       >
//         <h1
//           style={{
//             fontSize: "50px",
//             fontWeight: "600",
//             color: "#046A3A",
//             textAlign: "left",
//             width: "100%",
//             lineHeight: "60px",
//           }}
//         >
//           <span style={{ color: "black" }}> Our </span> Story
//         </h1>
//         <p
//           style={{
//             textAlign: "justify",
//             fontSize: "18px",
//             fontFamily: "Poppins,sans-serif",
//             fontWeight: "500",
//             paddingBlock: "20px",
//             width: "100%",
//           }}
//         >
//           Founded on the belief that entrepreneurship is as much about personal
//           growth as it is about business development, UdyamWell was born out of
//           a desire to create a nurturing environment for entrepreneurs. Our
//           founders, a diverse group of seasoned entrepreneurs and wellness
//           experts, noticed a gap in the support systems available to business
//           owners. They saw that while many programs focused on the financial and
//           operational aspects of business, few addressed the personal challenges
//           that also come with entrepreneurship.
//         </p>
//         <p
//           style={{
//             textAlign: "justify",
//             fontSize: "18px",
//             fontFamily: "Poppins,sans-serif",
//             fontWeight: "500",
//             paddingBlock: "20px",
//             width: "100%",
//           }}
//         >
//           As we grew, so did our community. Today, UdyamWell is more than just a
//           service; it’s a movement towards sustainable entrepreneurship. We've
//           helped hundreds of entrepreneurs find success, balance, and
//           fulfillment. Our story is still being written, with each new member
//           adding a chapter full of innovation, resilience, and shared success.
//         </p>
//         <p
//           style={{
//             textAlign: "justify",
//             fontSize: "18px",
//             fontFamily: "Poppins,sans-serif",
//             fontWeight: "500",
//             paddingBlock: "20px",
//             width: "100%",
//           }}
//         >
//           We invite you to join us on this journey, to be part of a community
//           that values your well-being as much as your business success.
//           Together, we can redefine what it means to be an entrepreneur in
//           today's world.
//         </p>
//       </Box>

//       <Box
//         sx={{
//           paddingBlock: "50px",
//           textAlign: "center",
//           width: "70%",
//           margin: "0 auto",
//         }}
//       >
//         <h1
//           style={{
//             fontSize: "50px",
//             fontWeight: "600",
//             color: "#046A3A",
//             textAlign: "center",
//             width: "100%",
//             lineHeight: "60px",
//           }}
//         >
//           <span style={{ color: "black" }}> The Team of </span> UdyamWell
//         </h1>
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             width: "100%",
//             justifyContent: "space-between",
//             marginTop: "60px",
//             marginBottom: "60px",
//           }}
//         >
//           <div className="card2">
//             <div className="card2-inner">
//               <div className="card2-front">
//                 <div className="card2-image-wrapper">
//                   <img src={founder} alt={""} className="card2-image" />
//                 </div>
//                 <div className="card2-info">
//                   <h3 className="card2-name">Vishal</h3>
//                   <div className="position-gradient">CHEIF OFFICER</div>
//                 </div>
//               </div>
//               <div className="card2-back">
//                 <p className="card2-description">Back Side Content Here</p>
//                 <div className="social-media-icons">
//                   <a
//                     href="https://www.facebook.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Facebook style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Twitter */}
//                   <a
//                     href="https://twitter.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Twitter style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Instagram */}
//                   <a
//                     href="https://www.instagram.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Instagram style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="card2 broad">
//             <div className="card2-inner">
//               <div className="card2-front">
//                 <div className="card2-image-wrapper">
//                   <img src={founder} alt={""} className="card2-image" />
//                 </div>
//                 <div className="card2-info">
//                   <h3 className="card2-name">Vishal</h3>
//                   <div className="position-gradient">CHEIF OFFICER</div>
//                 </div>
//               </div>
//               <div className="card2-back">
//                 <p className="card2-description">Back Side Content Here</p>
//                 <div className="social-media-icons">
//                   <a
//                     href="https://www.facebook.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Facebook style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Twitter */}
//                   <a
//                     href="https://twitter.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Twitter style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Instagram */}
//                   <a
//                     href="https://www.instagram.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Instagram style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="card2">
//             <div className="card2-inner">
//               <div className="card2-front">
//                 <div className="card2-image-wrapper">
//                   <img src={founder} alt={""} className="card2-image" />
//                 </div>
//                 <div className="card2-info">
//                   <h3 className="card2-name">Vishal</h3>
//                   <div className="position-gradient">CHEIF OFFICER</div>
//                 </div>
//               </div>
//               <div className="card2-back">
//                 <p className="card2-description">Back Side Content Here</p>
//                 <div className="social-media-icons">
//                   <a
//                     href="https://www.facebook.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Facebook style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Twitter */}
//                   <a
//                     href="https://twitter.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Twitter style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Instagram */}
//                   <a
//                     href="https://www.instagram.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Instagram style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             width: "100%",
//             justifyContent: "space-between",
//             marginTop: "60px",
//             marginBottom: "60px",
//           }}
//         >
//           <div className="card2">
//             <div className="card2-inner">
//               <div className="card2-front">
//                 <div className="card2-image-wrapper">
//                   <img src={founder} alt={""} className="card2-image" />
//                 </div>
//                 <div className="card2-info">
//                   <h3 className="card2-name">Vishal</h3>
//                   <div className="position-gradient">CHEIF OFFICER</div>
//                 </div>
//               </div>
//               <div className="card2-back">
//                 <p className="card2-description">Back Side Content Here</p>
//                 <div className="social-media-icons">
//                   <a
//                     href="https://www.facebook.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Facebook style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Twitter */}
//                   <a
//                     href="https://twitter.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Twitter style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Instagram */}
//                   <a
//                     href="https://www.instagram.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Instagram style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="card2 broad">
//             <div className="card2-inner">
//               <div className="card2-front">
//                 <div className="card2-image-wrapper">
//                   <img src={founder} alt={""} className="card2-image" />
//                 </div>
//                 <div className="card2-info">
//                   <h3 className="card2-name">Vishal</h3>
//                   <div className="position-gradient">CHEIF OFFICER</div>
//                 </div>
//               </div>
//               <div className="card2-back">
//                 <p className="card2-description">Back Side Content Here</p>
//                 <div className="social-media-icons">
//                   <a
//                     href="https://www.facebook.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Facebook style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Twitter */}
//                   <a
//                     href="https://twitter.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Twitter style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Instagram */}
//                   <a
//                     href="https://www.instagram.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Instagram style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="card2">
//             <div className="card2-inner">
//               <div className="card2-front">
//                 <div className="card2-image-wrapper">
//                   <img src={founder} alt={""} className="card2-image" />
//                 </div>
//                 <div className="card2-info">
//                   <h3 className="card2-name">Vishal</h3>
//                   <div className="position-gradient">CHEIF OFFICER</div>
//                 </div>
//               </div>
//               <div className="card2-back">
//                 <p className="card2-description">Back Side Content Here</p>
//                 <div className="social-media-icons">
//                   <a
//                     href="https://www.facebook.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Facebook style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Twitter */}
//                   <a
//                     href="https://twitter.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Twitter style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                   {/* Link to Instagram */}
//                   <a
//                     href="https://www.instagram.com/yourpage"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <Instagram style={{ fontSize: "40px", color: "#000000" }} />
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Box>
//       <div className="timeline-box">
//         <h1
//           style={{
//             fontSize: "50px",
//             fontWeight: "600",
//             color: "#046A3A",
//             textAlign: "center",
//             width: "100%",
//             lineHeight: "60px",
//             // sx: { fontSize: "0.5rem", md: { fontSize: "1rem" } },
//           }}
//         >
//           Our
//           <span style={{ color: "black" }}> Journey </span>
//         </h1>
//         <p style={{ textAlign: "center", margin: "20px auto" }}>
//           In UdyamWell, we aim to support and empower entrepreneurs by providing
//           them with essential resources, personalized mentoring, and access to a
//           supportive community.
//         </p>
//         <hr
//           style={{
//             height: "3px",
//             background: "#046A3A",
//             border: "none",
//             margin: "40px auto",
//             width: "100%",
//           }}
//         />
//         <JourneyMap />
//       </div>
//       <Box sx={{ mt: 8, textAlign: "center" }}>
//         <div
//           className="banner"
//           style={{
//             margin: "0px auto",
//             width: "100%",
//             alignItems: "flex-start",
//           }}
//         >
//           <div
//             className="bannerText"
//             style={{ width: "100%", margin: "0px auto" }}
//           >
//             <h1
//               style={{
//                 fontSize: "50px",
//                 fontWeight: "600",
//                 color: "#046A3A",
//                 textAlign: "center",
//                 width: "80%",
//                 lineHeight: "60px",
//                 margin: "0px auto",
//                 // sx: { fontSize: "0.5rem", md: { fontSize: "1rem" } },
//               }}
//             >
//               Media
//               <span style={{ color: "black" }}> and Recognition</span>
//             </h1>
//             <p
//               style={{ textAlign: "center", margin: "20px auto", width: "70%" }}
//             >
//               The recognitions and media features underscore our dedication to
//               empowering entrepreneurs and highlight the widespread impact of
//               our comprehensive support programs
//             </p>
//             <hr
//               style={{
//                 height: "3px",
//                 background: "#046A3A",
//                 border: "none",
//                 margin: "40px auto",
//                 width: "60%",
//               }}
//             />
//             <Box
//               className="countBox"
//               style={{ margin: "0px auto" }}
//               data-aos="fade-up"
//             >
//               <Box className="count"></Box>
//               <Box className="count">
//                 <img src={ondc} alt="ONDC" />

//                 <img src={sbi} alt="SBI Foundation" />

//                 <img src={microsoft} alt="Microsoft for Startups" />
//                 <img src={iitmandicatalyst} alt="IIT Mandi Catalyst" />
//               </Box>
//             </Box>
//             {/* <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "center",
//                 flexWrap: "wrap",
//                 gap: "20px",
//                 margin: "100px auto",
//               }}
//             >
//               <Boxs image={article1} imageUrl={news1} />
//               <Boxs
//                 image={article2}
//                 imageUrl={
//                   "https://www.entrepreneur.com/en-in/news-and-trends/how-the-countrys-largest-bank-is-supporting-social-startups/449434"
//                 }
//               />
//             </div> */}
//             <Box
//               sx={{ p: "0 55px" }}
//               textAlign={"center"}
//               width={"100%"}
//               display={"flex"}
//               flexDirection={"row"}
//               gap={"20px"}
//               justifyContent={"center"}
//               alignItems={"flex-start"}
//               marginBottom={"20px"}
//               marginTop={"60px"}
//             >
//               <h1
//                 className="Text_Ondch1"
//                 style={{ width: "60%", textAlign: "left", fontSize: "32px" }}
//               >
//                 {" "}
//                 Latest News
//               </h1>
//               <Button
//                 sx={{
//                   p: "10px 20px",
//                   borderRadius: "10px",
//                   border: "solid 1.5px",

//                   font: "Poppins,sans-serif",
//                   fontWeight: "500",

//                   textTransform: "initial",
//                   background: "linear-gradient( #2E8446 , #006400 )",
//                   color: "white",
//                   transition:
//                     "filter 0.4s ease, color 0.4s ease, background 0.3s ease",
//                   "&:hover": {
//                     filter: "drop-shadow(-2px 4px 4px   #0064003a  )",
//                     color: "#006400",
//                     background: "white",
//                   },
//                 }}
//                 variant="outlined"
//               >
//                 View All News
//               </Button>
//             </Box>
//             <hr
//               style={{
//                 height: "3px",
//                 background: "#046A3A",
//                 border: "none",
//                 margin: "0 auto",
//                 width: "70%",
//               }}
//             />
//             <TwoBoxes />
//           </div>
//         </div>
//       </Box>
//       <Box
//         sx={{ p: "0 55px" }}
//         textAlign={"center"}
//         width={"auto"}
//         display={"flex"}
//         flexDirection={"column"}
//         gap={"20px"}
//         justifyContent={"center"}
//         alignItems={"center"}
//         marginTop={"80px"}
//       >
//         <Box
//           sx={{ p: "0 55px" }}
//           textAlign={"center"}
//           width={"90%"}
//           display={"flex"}
//           flexDirection={"row"}
//           gap={"20px"}
//           justifyContent={"center"}
//           alignItems={"flex-start"}
//           marginBlock={"80px"}
//         >
//           <h1
//             className="Text_Ondch1"
//             style={{ width: "60%", textAlign: "left" }}
//           >
//             {" "}
//             They talk about it
//             <span style={{ color: "black" }}> Better</span> than us
//           </h1>
//           <p style={{ width: "60%", textAlign: "justify" }}>
//             Discover the success stories and firsthand experiences of our
//             diverse community of sellers on UdyamWell. Each testimonial provides
//             a unique perspective on how our platform, in conjunction with ONDC,
//             has empowered businesses to grow, reach wider markets, and achieve
//             remarkable sales growth.
//           </p>
//         </Box>
//         <div style={{ width: "84%", marginBottom: "160px" }}>
//           <TestimonialCarousel />
//         </div>
//       </Box>
//     </>
//   );
// };

// export default About;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./about.css";
// import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
// import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
// import HandshakeTwoToneIcon from "@mui/icons-material/HandshakeTwoTone";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import market from "../../src/assets/market.webp";
import collaboration from "../assets/collaboration.webp";
import empowerment from "../../src/assets/empowerment.webp";
import Carousel from "../components/Carousel";
import Bg_about from "../../src/assets/Bg_about.webp";
// import { useNavigate } from "react-router";
import founder from "../assets/founder.webp";
import article2 from "../assets/article2.webp";
import article1 from "../assets/article1.webp";
import news1 from "../assets/news1.jpg";
const AboutCard = ({ heading, text1, text2, lists, icon }) => {
  return (
    <div className="cardContainer2" style={{ height: "450px" }}>
      <span>
        <img src={icon} alt="" />
      </span>
      <h4 style={{ width: "90%", marginInline: "auto" }}>{heading}</h4>
      <p
        style={{
          textAlign: "justify",
          color: "black",
          fontWeight: "200",
          fontSize: "16px",
        }}
      >
        {text1}
      </p>
    </div>
  );
};

const Checkpoint = ({ cx, cy, heading, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleHoverEnter = () => {
    setIsOpen(true);
  };

  const handleHoverLeave = () => {
    setIsOpen(false);
  };

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r="24"
        fill="#046A3A"
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverLeave}
        style={{ cursor: "pointer" }}
      />
      <text
        x={cx}
        y={cy - 30}
        textAnchor="middle"
        fontSize="18"
        fontWeight="bold"
        fill="black"
      >
        {heading}
      </text>
      {isOpen && (
        <>
          <foreignObject x={cx - 65} y={cy + 40} width="140" height="600">
            <div
              style={{
                background: "#046A3A",
                padding: "4px",
                color: "white",
                borderRadius: "10px",
              }}
            >
              <p style={{ textAlign: "justify" }}>{description}</p>
            </div>
          </foreignObject>
        </>
      )}
    </g>
  );
};
const Checkpoint2 = ({ cx, cy, heading, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleHoverEnter = () => {
    setIsOpen(true);
  };

  const handleHoverLeave = () => {
    setIsOpen(false);
  };

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r="8"
        fill="#046A3A"
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverLeave}
        style={{ cursor: "pointer" }}
      />
      <text
        x={cx + 20}
        y={cy}
        textAnchor="start"
        fontSize="12"
        fontWeight="bold"
        fill="black"
      >
        {heading}
      </text>
      {isOpen && (
        <>
          <foreignObject x={cx + 10} y={cy + 2} width="80" height="100">
            <div>
              <p
                style={{
                  background: "#046A3A",
                  color: "white",
                  fontSize: "8px",
                  padding: "4px",
                }}
              >
                {description}
              </p>
            </div>
          </foreignObject>
        </>
      )}
    </g>
  );
};

const Boxs = ({ image, imageUrl, width }) => {
  const handleLinkClick = (e) => {
    e.preventDefault();
    // Open pop-up window with image
    window.open(imageUrl, "_blank", "width=80%,height=auto");
  };
  return (
    <Link to="#" style={{ textDecoration: "none" }} onClick={handleLinkClick}>
      <img className="box-image" src={image} alt="B" />
    </Link>
  );
};
export const AboutServices = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  // const navigate = useNavigate();
  // const handelClick = () => {
  //   navigate("/services");
  // };
  return (
    <>
      <div className="aboutus-box-head">
        <h3>
          {t("empower")}{" "}
          <span style={{ color: "#000000" }}>{t("bharatpreneur")}</span>{" "}
          {t("journey")}
        </h3>
        <Typography
          variant="h6"
          fontWeight={"500"}
          fontSize={"16px"}
          className="serviceSubHeading"
          textAlign={"justify"}
          marginTop={"2rem"}
          color={"black"}
          fontFamily={"Poppins, sans-serif"}
          width={"100%"}
        >
          {" "}
          {t("aboutbody_home")}
        </Typography>
        {/* <div className="homeAboutButton">
          <button
            style={{ cursor: "pointer", borderRadius: "10px" }}
            onClick={handelClick}
          >
            {t("readmore_btn")}
          </button>
        </div> */}
      </div>
      <div className="aboutServices" style={{ marginTop: "2rem" }}>
        <AboutCard
          heading={t("home_aboutcardheading1")}
          icon={empowerment}
          text1={t("home_aboutcardbody1")}
        />
        <AboutCard
          icon={collaboration}
          heading={t("home_aboutcardheading2")}
          text1={t("home_aboutcardbody2")}
        />
        <AboutCard
          icon={market}
          heading={t("home_aboutcardheading3")}
          text1={t("home_aboutcardbody3")}
        />
      </div>
    </>
  );
};

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="landingPageCover">
        <img
          src={Bg_about}
          width="100vw"
          alt="landing"
          className="landingPageCover__animation"
        />
      </div>
      <Box sx={{ mt: 20, textAlign: "center" }}>
        <Typography
          variant="h1"
          sx={{
            color: "#046A3A",
            fontFamily: "Poppins,sans-serif",
            fontWeight: 600,
            fontSize: "50px",
            "@media (max-width: 500px)": {
              fontSize: "32px",
            },
          }}
        >
          Empowering <span style={{ color: "black" }}>Rural Communities </span>:
          Our Story
        </Typography>
        <Typography
          className="aboutText"
          sx={{
            textAlign: "center",
            margin: "10px auto",
            width: "70%",
            fontWeight: "500",
            fontFamily: "Poppins,sans-serif",
            padding: "0 20px",
            fontSize: "18px",
            "@media (max-width: 500px)": {
              width: "80%",
            },
          }}
          variant="p"
        >
          A spirit of transition is present in our country. From villages to
          cities, sparks of ideas connect us together. <br />
          We at UdyamWell are passionate about helping rural communities in
          consolidating their ideas.
        </Typography>
      </Box>

      <Carousel />

      <Box sx={{ paddingBlock: "50px", textAlign: "center" }}>
        <div className="banner" style={{ margin: "0px auto", width: "70%" }}>
          <div className="bannerText">
            <Typography
              variant="h1"
              sx={{
                color: "#046A3A",
                fontFamily: "Poppins,sans-serif",
                fontWeight: 600,
                fontSize: "50px",
                "@media (max-width: 500px)": {
                  fontSize: "32px",
                },
              }}
            >
              <span style={{ color: "black" }}> Founder's </span> Words
            </Typography>
            <cite
              style={{
                textAlign: "justify",
                fontSize: "18px",
                fontFamily: "Poppins,sans-serif",
                fontWeight: "500",
                paddingBlock: "20px",
                width: "100%",
              }}
            >
              As Mahatma Gandhi famously said,{" "}
              <spam style={{ fontWeight: "bold" }}>
                "The soul of India lives in its villages."
              </spam>{" "}
              I strongly believe that technology can play a transformative role
              in rural areas. With this vision in mind, we have launched
              UdyamWell, not just as a platform but as a movement dedicated to
              building a better Bharat that serves the world.
            </cite>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div className="about_"></div>
              <p>Sachin Korla</p>
            </div>
          </div>
          <div
            className="bannerImg"
            style={{
              sx: { width: "30%" },
              md: { width: "40%" },
              lg: { width: "70%" },
            }}
          >
            <img src={founder} alt="" width={{ width: "100%" }} />
          </div>
        </div>
      </Box>
      {/* <Box className="countBox2" style={{ width: "70%" }}>
        <Box className="count2">
          <PeopleAltOutlinedIcon sx={{ fontSize: "50px" }} />{" "}
          <Typography
            variant="h5"
            sx={{ ml: 2, color: "#236836", fontWeight: 600 }}
          >
            {t("aboutus_count1")}
          </Typography>
        </Box>
        <Box className="count2">
          <LaptopMacIcon sx={{ fontSize: "50px" }} />{" "}
          <Typography
            variant="h5"
            sx={{ ml: 2, color: "#236836", fontWeight: 600 }}
          >
            {t("aboutus_count2")}
          </Typography>
        </Box>
        <Box className="count2">
          <PersonSharpIcon sx={{ fontSize: "50px" }} />{" "}
          <Typography
            variant="h5"
            sx={{ ml: 2, color: "#236836", fontWeight: 600 }}
          >
            {t("aboutus_count3")}
          </Typography>
        </Box>
      </Box> */}
      <div className="timeline-box">
        <Typography
          variant="h1"
          sx={{
            color: "#046A3A",
            fontFamily: "Poppins,sans-serif",
            fontWeight: 600,
            fontSize: "50px",
            margin: "0 auto",
            textAlign: "center",
            "@media (max-width: 500px)": {
              fontSize: "32px",
            },
          }}
        >
          Our
          <span style={{ color: "black" }}> Remarkable </span> Milestones
        </Typography>

        <svg
          width="100%"
          style={{ paddingBlock: "100px" }}
          viewBox="0 -40 1200 350"
          className="timeline-big"
        >
          <path
            d="M 0,100 Q 150,200 300,100 Q 450,0 600,100 Q 750,200 900,100 Q 1050,0 1200,100"
            fill="none"
            stroke="green"
            strokeWidth="6"
            strokeDasharray="5"
          />

          <text x="2%" y="100" fontSize="34" textAnchor="middle">
            📍
          </text>
          <text x="98%" y="70" fontSize="34" textAnchor="middle">
            🏁
          </text>
          <Checkpoint
            cx={140}
            cy={150}
            heading="June"
            description="Company Incorporated."
          />
          <Checkpoint
            cx={300}
            cy={100}
            heading="July"
            description="Completed a successful pilot with our target customers."
          />
          <Checkpoint
            cx={500}
            cy={55}
            heading="August"
            description="Recognition by Startup India"
          />
          <Checkpoint
            cx={700}
            cy={145}
            heading="October"
            description=" Launched our website and organized the first Rural Mart."
          />
          <Checkpoint
            cx={900}
            cy={100}
            heading="February"
            description="Gained support from the Meity Startup Hub."
          />
          <Checkpoint
            cx={1080}
            cy={50}
            heading="March"
            description="Showcase at the World Youth Festival in Russia and Startup Mahakumbh."
          />
        </svg>
        <svg
          width="100%"
          style={{ paddingTop: "100px" }}
          viewBox="0 0 150 450"
          className="timeline-small"
        >
          <path
            d="M 50,0 L 50,380"
            fill="none"
            stroke="green"
            strokeWidth="3"
            strokeDasharray="6"
          />

          <text x="50" y="15" fontSize="14" textAnchor="middle">
            📍
          </text>
          <text x="56" y="375" fontSize="14" textAnchor="middle">
            🏁
          </text>
          <Checkpoint2
            cx={50}
            cy={30}
            heading="June"
            description="Incubation at IIT Mandi"
          />
          <Checkpoint2
            cx={50}
            cy={90}
            heading="July"
            description="Onboarding of first 15 Rural Enterprises"
          />
          <Checkpoint2
            cx={50}
            cy={150}
            heading="August"
            description="Recognition by Startup India"
          />
          <Checkpoint2
            cx={50}
            cy={210}
            heading="October"
            description="Successful organization of ` Rural Mart` at IIT Mandi during HST Edition 7 in collaboration with IIT Mandi Catalyst "
          />
          <Checkpoint2
            cx={50}
            cy={270}
            heading="February"
            description="Receipt of Grant from Meity Startup Hub"
          />
          <Checkpoint2
            cx={50}
            cy={330}
            heading="March"
            description="Representation of UdyamWell at World Youth Festival in Russia"
          />
        </svg>
      </div>
      <Box sx={{ mt: 8, textAlign: "center" }}>
        <div
          className="banner"
          style={{
            margin: "0px auto",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <div
            className="bannerText"
            style={{ width: "100%", margin: "0px auto" }}
          >
            <Typography
              variant="h1"
              sx={{
                color: "#046A3A",
                fontFamily: "Poppins,sans-serif",
                fontWeight: 600,
                fontSize: "50px",
                width: "90%",
                margin: "0 auto",
                textAlign: "center",
                "@media (max-width: 500px)": {
                  fontSize: "32px",
                },
              }}
            >
              Media
              <span style={{ color: "black" }}> and Recognition</span>
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "20px",
                margin: "100px auto",
              }}
            >
              <Boxs image={article1} imageUrl={news1} />
              <Boxs
                image={article2}
                imageUrl={
                  "https://www.entrepreneur.com/en-in/news-and-trends/how-the-countrys-largest-bank-is-supporting-social-startups/449434"
                }
              />
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default About;
