// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.heading {
  text-align: center;
}
.container-content {
  width: 80%;
  margin: auto;
  line-height: 1rem;
  text-align: justify;
}

.container-content p {
  font-size: 12px;
  text-align: left;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-box {
  background-color: #fff;
  width: 100%;
  padding: 20px;
  position: relative;
  overflow-y: auto; /* Add scrollbar when content exceeds height */
}

.close {
  position: sticky;
  top: 10px;
  
  cursor: pointer;
  font-size: 20px;
}

.popup-content {
  max-height: 80vh; /* Limit max height of popup content */
}

.container-content {
  padding-right: 20px; /* Add right padding to avoid scrollbar overlapping content */
}
.close:hover,
.close:focus {
  color: #000000;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/TermsCondition.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,gBAAgB,EAAE,8CAA8C;AAClE;;AAEA;EACE,gBAAgB;EAChB,SAAS;;EAET,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,gBAAgB,EAAE,sCAAsC;AAC1D;;AAEA;EACE,mBAAmB,EAAE,6DAA6D;AACpF;AACA;;EAEE,cAAc;EACd,qBAAqB;AACvB","sourcesContent":["\n.heading {\n  text-align: center;\n}\n.container-content {\n  width: 80%;\n  margin: auto;\n  line-height: 1rem;\n  text-align: justify;\n}\n\n.container-content p {\n  font-size: 12px;\n  text-align: left;\n}\n.popup {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n}\n\n.popup-box {\n  background-color: #fff;\n  width: 100%;\n  padding: 20px;\n  position: relative;\n  overflow-y: auto; /* Add scrollbar when content exceeds height */\n}\n\n.close {\n  position: sticky;\n  top: 10px;\n  \n  cursor: pointer;\n  font-size: 20px;\n}\n\n.popup-content {\n  max-height: 80vh; /* Limit max height of popup content */\n}\n\n.container-content {\n  padding-right: 20px; /* Add right padding to avoid scrollbar overlapping content */\n}\n.close:hover,\n.close:focus {\n  color: #000000;\n  text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
