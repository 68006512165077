// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ondc-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-block: 8rem;
}
.inputs{
    width: 100%;
}
.label2{
    width: 100%;
    padding-inline: 12%;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins",sans-serif;
}
.formONDC{
    display: flex;
    flex-direction: column;
    background-color: white;
    filter: drop-shadow(5px 5px 10px #00000052);
    border-radius: 20px;
    margin: 10px;
    width: 80%;
}
.btnForm{
    margin-inline:auto;
    margin-block: 40px;
    
}`, "",{"version":3,"sources":["webpack://./src/components/forms/SellOnONDCForn.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,QAAQ;IACR,eAAe;IACf,gBAAgB;IAChB,iCAAiC;AACrC;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,2CAA2C;IAC3C,mBAAmB;IACnB,YAAY;IACZ,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,kBAAkB;;AAEtB","sourcesContent":[".ondc-form{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    margin-block: 8rem;\n}\n.inputs{\n    width: 100%;\n}\n.label2{\n    width: 100%;\n    padding-inline: 12%;\n    padding-top: 40px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    gap: 5px;\n    font-size: 18px;\n    font-weight: 500;\n    font-family: \"Poppins\",sans-serif;\n}\n.formONDC{\n    display: flex;\n    flex-direction: column;\n    background-color: white;\n    filter: drop-shadow(5px 5px 10px #00000052);\n    border-radius: 20px;\n    margin: 10px;\n    width: 80%;\n}\n.btnForm{\n    margin-inline:auto;\n    margin-block: 40px;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
