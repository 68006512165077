import about_overlay from "./about_overlay.webp";
import advance_feature_bg from "./advance_feature_bg.webp";
import advance_feature_img from "./advance_feature_img.webp";
import banner_bg from "./banner_bg.webp";
import banner_img from "./banner_img.webp";
import breadcrumb from "./breadcrumb.webp";
import favicon from "./favicon.png";
import learning_img from "./learning_img.webp";
import LogGradientText_BlackBG from "./LogGradientText_BlackBG.webp";
import logo from "./logo.webp";
import logo1 from "./logo1.png";
import LogoBlackText_whiteBG from "./LogoBlackText_whiteBG.png";
import Logo_Gradient_BlackBG from "./Logo_Gradient_BlackBG.webp";
import quote from "./quote.png";
import service_bg_2 from "./service_bg_2.webp";
import single_page_logo from "./single_page_logo.png";
import color_star from "./icon/color_star.svg";
import left from "./icon/left.svg";
import play from "./icon/play.svg";
import quate from "./icon/quate.svg";
import right from "./icon/right.svg";
import star from "./icon/star.svg";
import icon_1 from "./animate_icon/icon_1.png";
import icon_2 from "./animate_icon/icon_2.png";
import icon_3 from "./animate_icon/icon_3.png";
import icon_4 from "./animate_icon/icon_4.png";
import icon_5 from "./animate_icon/icon_5.png";
import icon_7 from "./animate_icon/icon_7.png";
import menu from "./menu.svg";
import icon_8 from "./animate_icon/icon_8.png";
import icon_9 from "./animate_icon/icon_9.png";
import close from "./close.svg";
import a from "./a.webp";
import menuBlack from "./menuBlack.webp";
import closeBlack from "./closeBlack.webp";
import twitter from "./twitter.png";
import fb from "./fb.webp";
import ig from "./ig.webp";
import linkedIn from "./linkedIn.webp";
import learning_img_bg from "./learning_img_bg.webp";
import register from "./register.jpg";
import login from "./login.webp";
import landingPageBg from "./landingPageBg.jpg";
import landingPageBg_About from "./landingPageBg_About.jpg";
import landingPageBg_small from "./landingPageBg_small.jpg";
import Udyamwell_Logo_Standee from "./Udyamwell_Logo_Standee.png";
import udyamsheel from "./udyamsheel.webp";
import lms from "./lms.webp";
import service from "./service.webp";
import udyamsheelQr from "./udyamsheelQr.webp";
import learning_about from "./learning_about.png";
import front_view_hands_using_smartphone from "./front_view_hands_using_smartphone.webp";
import hand_holding_smartphone from "./hand_holding_smartphone.webp";
import playstore from "./playstore.png";
import main from "./main.webp";
import udyamsathi from "./udyamsathi.webp";
import udyamsathiQR from "./udyamsathiQR.png";
export {
  menuBlack,
  closeBlack,
  menu,
  close,
  a,
  about_overlay,
  advance_feature_img,
  advance_feature_bg,
  banner_bg,
  banner_img,
  breadcrumb,
  favicon,
  learning_img,
  learning_img_bg,
  LogGradientText_BlackBG,
  logo,
  logo1,
  Logo_Gradient_BlackBG,
  LogoBlackText_whiteBG,
  quote,
  service_bg_2,
  single_page_logo,
  color_star,
  left,
  play,
  quate,
  right,
  star,
  icon_1,
  icon_2,
  icon_3,
  icon_4,
  icon_5,
  icon_7,
  icon_8,
  icon_9,
  twitter,
  ig,
  fb,
  linkedIn,
  register,
  login,
  Udyamwell_Logo_Standee,
  landingPageBg,
  landingPageBg_About,
  udyamsheel,
  udyamsheelQr,
  udyamsathi,
  udyamsathiQR,
  service,
  landingPageBg_small,
  lms,
  learning_about,
  hand_holding_smartphone,
  front_view_hands_using_smartphone,
  playstore,
  main,
};
