import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { landingPageBg_small } from "../assets";
import { useTranslation } from "react-i18next";
import Bg_login from "../assets/Bg_login.png";
import User0 from "../assets/Testinomials.webp";
import User1 from "../assets/Testinomials1.webp";
import User2 from "../assets/Testinomials2.webp";
// import { logo1 } from "../assets/index.js";
import "./styles/register.css";
import "./TestimonialCard.css";

// import { logo1 } from "../assets/index.js";
const Email = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    user && navigate("/");
  }, [navigate, user]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      alert("Please enter your registered mail");
      return;
    }

    axios
      .post("https://udyamwell-backend-prod.azurewebsites.net/users/sendOtp", {
        email: email,
      })

      .then((res) => {
        if (res.data && res.data.message) {
          Swal.fire(
            `${res.data.message}`,
            "Please check your Email for link.",
            "success"
          );
        } else {
          console.error("Unexpected response format:", res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
        if (err.response && err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError("An error occurred while processing your request.");
        }
      });
  };
  const testimonials = [
    {
      name: "Ajay",
      text: "उद्यमवेल का उद्यमशील भारत प्रोग्राम और उनका समर्थन मेरे लिए एक वरदान है। इससे मेरे व्यवसाय को बढ़ाने में बहुत मदद मिली है। उद्यमवेल से जुड़े हुए कुछ ही महीनों में मेरे आय के स्रोतों में वृद्धि हुई है। ",
      photo: User0,
    },
    {
      name: "Kalyani Keerti",
      text: "उद्यमवेल ने मुझे कैसे काम को सही तरीके से करके आगे बढ़ा जा सकता है ! उद्यमवेल में  जुड़ने के बाद से मेरा छोटा बिजनेस मुझे एक बड़े बिजनेस का आकार दिखा रहा है इस पूरी टीम को दिल से बहुत बहुत धन्यवाद। उद्यमवेल से मुझे जोड़ने के लिए सचिन जी बहुत-बहुत  धन्यवाद!",
      photo: User1,
    },
    {
      name: "Srinithi Sripelly",
      text: "UdyamWell's capacity-building program empowered our rural women entrepreneurs with valuable knowledge and skills. We are grateful for their dedication and look forward to a long-term partnership.",
      photo: User2,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [testimonials.length]);
  return (
    <div>
      <div className="landingPageCover">
        <img
          src={Bg_login}
          width="100vw"
          alt="landing"
          className="landingPageCover__animation"
        />
      </div>
      {/* <div className="Signup">
        <form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0rem auto 0rem auto",
            }}
            color={"#236836"}
          >
            <Stack width={"100%"}>
              <img src={logo1} style={{ width: "60px" }} alt="" />
              <h1
                className="subHeading"
                style={{
                  fontSize: "50px",
                  fontWeight: "600",
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "center",
                  marginBottom: "40px",
                }}
              >
                <span style={{ color: "black" }}>{t("sign")}</span>
                {t("up")}
              </h1>
            </Stack>
          </Box>
        </form>
        <div className="signup-form">
          <div className="mainForm">
            {error && <p>{error}</p>}
            <Box>
              <Stack
                alignSelf={"center"}
                sx={{ width: "100%", margin: "1rem auto" }}
                spacing={4}
              >
                <TextField
                  fullWidth
                  sx={{
                    width: "100%",
                    backgroundColor: "#E0F1E5",
                    "& .MuiOutlinedInput-input::placeholder": {
                      color: "black",
                      fontWeight: "400",
                      alignSelf: "center",
                    },
                  }}
                  type={"email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label={t("formph_mail")}
                />
              </Stack>
            </Box>
            <Box>
              <Typography sx={{ mt: 3, textAlign: "center" }}>
                Already have an Account?{" "}
                <Link to="/login">
                  <span style={{ color: "green", textDecoration: "underline" }}>
                    Click here to Login
                  </span>
                </Link>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "white",
                  padding: "10px 60px",
                  borderRadius: "10px",
                  fontWeight: "400",
                  textTransform: "inherit",
                  border: "none",
                  background:
                    "linear-gradient(90deg, rgba(46,132,70,1) 0%, rgba(0,100,0,1) 100%);",
                }}
                onClick={handleSubmit}
              >
                {t("submit_btn")}
              </Button>
            </Box>
          </div>
        </div>
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: "8rem",
        }}
      >
        {" "}
        <div className="loginContainer">
          <div className="login-form" data-aos="fade-left">
            <div className="mainForm">
              <form
                style={{
                  paddingBlock: "40px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onSubmit={handleSubmit}
              >
                {error && (
                  <Alert
                    sx={{ fontSize: "15px", p: 0.3, mt: 2, mb: 0 }}
                    severity="error"
                  >
                    {error}
                  </Alert>
                )}
                <h1 className="campuslogin-head">
                  <span style={{ color: "#046A3A" }}>{t("sign")}</span>
                  {t("up")}
                </h1>
                <Stack
                  alignSelf={"center"}
                  sx={{ width: "100%", margin: "1rem auto" }}
                  spacing={4}
                >
                  {/* <CustomTextArea
                  id="customTextArea"
                  name="customTextArea"
                  rows="1"
                  type="text"
                  placeholder={t("formph_mail")}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                /> */}
                  <TextField
                    fullWidth
                    sx={{
                      width: "100%",
                      backgroundColor: "#E0F1E5",
                      "& .MuiOutlinedInput-input::placeholder": {
                        color: "black",
                        fontWeight: "400",
                        alignSelf: "center",
                      },
                    }}
                    type={"email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label={t("formph_mail")}
                  />
                  {/* <CustomTextArea
                  id="customTextArea"
                  name="customTextArea"
                  rows="1"
                  type="text"
                  placeholder={t("formph_password")}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  margin="none"
                /> */}
                </Stack>
                <Box>
                  <Typography sx={{ mt: 3, textAlign: "center" }}>
                    Already have an Account?{" "}
                    <Link to="/login">
                      <span style={{ color: "green", textDecoration: "none" }}>
                        Click here to Login
                      </span>
                    </Link>
                  </Typography>
                </Box>
                <div className="btns-2" style={{ alignItems: "flex-end" }}>
                  <Box sx={{ mt: 3 }}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        color: "white",
                        padding: "10px 60px",
                        borderRadius: "10px",
                        fontWeight: "400",
                        textTransform: "inherit",
                        border: "none",
                        background:
                          "linear-gradient(90deg, rgba(46,132,70,1) 0%, rgba(0,100,0,1) 100%);",
                      }}
                    >
                      {t("submit_btn")}
                    </Button>
                  </Box>
                </div>
              </form>
            </div>

            {/* <div className="contact-info">
            <span className="contact-info__icon">
              <HomeOutlinedIcon />
            </span>
            <div className="media-body">
              <a
                href="https://maps.app.goo.gl/DCBAgSxqtdhBWvYPA"
                target="_blank"
                rel="noreferrer"
              >
                <h3>{t("address_body1")}</h3>
                <p>{t("address_body2")}</p>
              </a>
            </div>
          </div>
          <div className="contact-info">
            <span className="contact-info__icon">
              <PhoneIphoneOutlinedIcon />
            </span>
            <div className="media-body">
              <a href="tel:+91 7978949923" target="_blank" rel="noreferrer">
                <h3>+91 7978949923</h3>
                <p>{t("phone_time")}</p>
              </a>
            </div>
          </div>
          <div className="contact-info">
            <span className="contact-info__icon">
              <MailOutlinedIcon />
            </span>
            <div className="media-body">
              <a
                href="mailto:admin@udyamwell.com"
                target="_blank"
                rel="noreferrer"
              >
                <h3>admin@udyamwell.com</h3>
              </a>
              <p>{t("mail_body")}</p>
            </div>
          </div>
          <div className="contact-info">
            <span className="contact-info__icon">
              <WhatsAppIcon />
            </span>
            <div className="media-body">
              <a
                href="https://chat.whatsapp.com/GvR6jeujogr8YeOvspVF1F"
                target="_blank"
                rel="noreferrer"
              >
                <h3>{t("contact_whatsapp")}</h3>
              </a>
              <p>{t("contact_whatsappbody")}</p>
            </div>
          </div> */}
          </div>
          <div className="testimonial-card" data-aos="fade-right">
            {" "}
            <p className="testimonial-text">
              {testimonials[currentIndex].text}
            </p>
            <img src={testimonials[currentIndex].photo} alt="Testimonial" />
            <p className="testimonial-name">
              {testimonials[currentIndex].name}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;
