import React, { useEffect, useState, useRef } from "react";
import VideoLectureCard from "./VideoLectureCard";
import "./styles/courseCard.css";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleCource } from "../slices/videos";
import "./CustomVideoPlayer.css";
import Slider from "@mui/material/Slider";
import Forward10Icon from "@mui/icons-material/Forward10";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import IosShareIcon from "@mui/icons-material/IosShare";
import ReactPlayer from "react-player";
import SearchBox from "../components/SearchBox";
import Bg_course from "../assets/Bg_course.webp";
import banner from "../assets/banner.webp";
import LoadingSpinner from "./LoadingSpinner";
import TestimonialCarousel from "../components/TestimonialCarousel";
import CourseCard2 from "../components/CourseCard2";
const SingleCoursePage = () => {
  const { all_courses } = useSelector((state) => state.courses);
  const { id } = useParams();
  const { courseName, lectures } = useSelector((state) => state.lectures);
  const dispatch = useDispatch();
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [played, setPlayed] = useState(0);
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  // const [isFullScreen, setIsFullScreen] = useState(false);

  const handleSearch = (query) => {
    console.log("Search query:", query);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    dispatch(fetchSingleCource(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (lectures && lectures.length > 0) {
      setSelectedVideoUrl(
        `https:/udyamwell-backend-prod.azurewebsites.net/uploads/course/videos/${lectures[0].video}`
      );
    }
  }, [lectures]);

  const handleCardClick = (videoUrl, index) => {
    setSelectedVideoUrl(videoUrl);
    setSelectedVideoIndex(index);
  };

  const handleSkip = (seconds) => {
    const currentTime = playerRef.current.getCurrentTime();
    playerRef.current.seekTo(currentTime + seconds, "seconds");
  };

  const divStyle = {
    transform: "scaleX(-1)",
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    playerRef.current.getInternalPlayer().volume = newValue;
    setIsMuted(newValue === 0);
  };

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
    const newVolume = isMuted ? 1 : 0;
    setVolume(newVolume);
    playerRef.current.getInternalPlayer().volume = newVolume;
  };

  const handleTimelineChange = (event, newValue) => {
    setPlayed(newValue);
    const newPlayedSeconds = (newValue / 100) * playerRef.current.getDuration();
    playerRef.current.seekTo(newPlayedSeconds, "seconds");
  };
  const [selectedOption, setSelectedOption] = useState(1);

  const renderComponent = () => {
    switch (selectedOption) {
      case 1:
        return <div>Content for Option 1</div>;
      case 2:
        return <div>Content for Option 2</div>;
      case 3:
        return <div>Content for Option 3</div>;
      default:
        return null;
    }
  };

  return (
    <div className="singleCourse" style={{ width: "100%" }}>
      <div className="landingPageCover">
        <img
          src={Bg_course}
          style={{ height: "130vh" }}
          alt="landing"
          className="landingPageCover__animation"
        />
      </div>
      <div
        className="mainVidContainer"
        style={{ width: "80%", margin: "0px auto" }}
      >
        <div
          className="mainVid"
          style={{ marginBottom: "50px", height: "100vh" }}
        >
          <div className="top-section">
            <h1>
              Udyam<span style={{ color: "green" }}>Well</span> Learning System
            </h1>
            <div className="search">
              <SearchBox onSearch={handleSearch} />
            </div>
          </div>
          <hr className="Videohr" />
          <div className="mid-section">
            <div className="custom-video-player">
              <ReactPlayer
                ref={playerRef}
                url={selectedVideoUrl}
                playing={isPlaying}
                volume={volume}
                onProgress={(progress) =>
                  setPlayed((progress.played * 100).toFixed(2))
                }
                onBuffer={() => setIsBuffering(true)}
                onBufferEnd={() => setIsBuffering(false)}
                controls={false}
                width="100%"
                height="100%"
              />
              {isBuffering && <LoadingSpinner />}
              <div className="timeline">
                <div className="progress-bar">
                  <Slider
                    value={played}
                    onChange={handleTimelineChange}
                    min={0}
                    max={100}
                    step={0.1}
                  />
                </div>
                <div className="custom-controls"></div>
                <div className="other-controls">
                  <div className="left-controls">
                    <button onClick={handlePlayPause}>
                      {isPlaying ? (
                        <PauseIcon sx={{ fontSize: 36 }} />
                      ) : (
                        <PlayArrowIcon sx={{ fontSize: 36 }} />
                      )}
                    </button>
                    <button onClick={() => handleSkip(-10)}>
                      <Forward10Icon sx={{ fontSize: 36 }} style={divStyle} />
                    </button>
                    <button onClick={() => handleSkip(10)}>
                      <Forward10Icon sx={{ fontSize: 36 }} />
                    </button>
                    <div
                      className="volume-button"
                      onMouseEnter={() => setShowVolumeSlider(true)}
                      onMouseLeave={() => setShowVolumeSlider(false)}
                    >
                      {" "}
                      <button onClick={handleToggleMute}>
                        {isMuted ? (
                          <VolumeOffIcon sx={{ fontSize: 36 }} />
                        ) : (
                          <VolumeUpIcon sx={{ fontSize: 36 }} />
                        )}
                      </button>
                      {showVolumeSlider && (
                        <div className="volume-slider">
                          <Slider
                            sx={{ fontSize: 36 }}
                            value={volume}
                            onChange={handleVolumeChange}
                            min={0}
                            max={1}
                            step={0.01}
                            style={{
                              width: "90px",
                              transform: "rotate(0deg)",
                              marginLeft: "10px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="View-controls">
                <div className="view-right">
                  <VisibilityIcon />
                  <span>100 views</span>
                </div>
                <div className="view-right">
                  <FavoriteBorderIcon />
                  <ChatBubbleOutlineIcon />
                  <IosShareIcon />
                </div>
              </div>
              <div className="Description">
                <h1>{courseName}</h1>
                <div className="date-uploaded" style={{ color: "grey" }}>
                  26 January 2023
                </div>
                <div className="desc-txt">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Voluptate ratione veritatis aperiam quas, voluptates at?
                  Accusantium quis debitis iure reprehenderit nemo minima ipsam
                  quaerat, aperiam vel, incidunt esse voluptate at.
                </div>
              </div>
            </div>
            <div className="recommendations" style={{ alignItems: "center" }}>
              <div className="playlist-banner">
                <img style={{ width: "100%" }} src={banner} alt="Banner" />
              </div>
              <Box sx={{ mt: 2, ml: 2 }}>
                <VideoLectureCard
                  lectures={lectures}
                  type={"video"}
                  onCardClick={handleCardClick}
                  selectedVideoIndex={selectedVideoIndex}
                />
              </Box>
            </div>
          </div>
        </div>
      </div>

      <section
        id="courseSection"
        style={{ width: "80%", margin: "80px auto 0px auto" }}
      >
        <h2>Course Detailed Information</h2>
        <p>A one line description goes here.</p>
        <div className="options">
          <button
            className={selectedOption === 1 ? "active" : ""}
            onClick={() => setSelectedOption(1)}
          >
            Overview
          </button>
          <button
            className={selectedOption === 2 ? "active" : ""}
            onClick={() => setSelectedOption(2)}
          >
            Q&A
          </button>
          <button
            className={selectedOption === 3 ? "active" : ""}
            onClick={() => setSelectedOption(3)}
          >
            Reviews
          </button>
        </div>
        <div className="componentContainer">{renderComponent()}</div>
      </section>
      <Box
        sx={{
          width: "100%",
          margin: "6rem 0 0 0",
          paddingBlock: "40px",
          background: "#2E84461F",
        }}
      >
        <div style={{ width: "80%", margin: "0 auto" }}>
          <div>
            <div>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "500",
                }}
              >
                Recommended courses for you
              </Typography>
              <Typography sx={{ mt: 1 }} variant="p">
                from your learnings
              </Typography>
            </div>
          </div>
          <div
            className="CourseContainer"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "103%",
              margin: "0 auto",
              paddingTop: "40px",
            }}
          >
            <CourseCard2 courses={all_courses} type={"course"} />
          </div>
        </div>
      </Box>
      <Box sx={{ width: "80%", margin: "6rem auto 6rem auto" }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "500",
            width: "400px",
            mb: 4,
          }}
        >
          What our students are saying about us
        </Typography>
        <TestimonialCarousel />
      </Box>
    </div>
  );
};

export default SingleCoursePage;
