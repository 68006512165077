// import React, { useState } from "react";
// import "./SellOnONDCForn.css";
// import { Button } from "@mui/material";
// import Swal from "sweetalert2";
// import axios from "axios";
// const SellOnONDCForm = () => {
//   // const [formData, setFormData] = useState({
//   //   name: "",
//   //   email: "",
//   //   phoneNum: "",
//   //   eName: "",
//   //   businessAdd: "",
//   //   businessInvolved: "",
//   // });

//   // const handleChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setFormData({
//   //     ...formData,
//   //     [name]: value,
//   //   });
//   // };

//   // const handleSubmit = (e) => {
//   //   e.preventDefault();
//   //   axios
//   //     .post(
//   //       "https://script.google.com/macros/s/AKfycbyKWdxq0GstNWaOZobKpFBwHdvNgBP9KXvz-zNLcxwyYs8sQFZRKcUlRwMFxZFhYf7b/exec",
//   //       formData
//   //     )
//   //     .then((response) => {
//   //       if (response.status === 200) {
//   //         Swal.fire("Thank you for Submitting!");
//   //       } else {
//   //         Swal.fire("Request not processed, please try again!");
//   //       }
//   //     })
//   //     .catch((error) => {
//   //       console.error(error);
//   //       Swal.fire("An error occurred, please try again later!");
//   //     });
//   // };
//   function Submit(e) {
//     const formEle = document.querySelector("form");
//     const formDatab = new FormData(formEle);
//     fetch(
//       "https://script.google.com/macros/s/AKfycbzkCUwHcshRO2lzwjRres5RERNPNye08APz26QjxzJpwycq1u5fKGa01D_etLZ7_Ewa/exec",
//       {
//         method: "POST",
//         body: formDatab,
//       }
//     )
//       .then(() => {
//         Swal.fire("Thank you for Submitting!");
//       })
//       .catch((error) => {
//         console.error(error);
//         Swal.fire("An error occurred, please try again later!");
//       });
//   }

//   return (
//     <form className="formONDC" onSubmit={(e) => Submit(e)}>
//       <h1
//         className="section_tittle text-center mt-5 mb-4"
//         style={{ color: "green", textAlign: "center", paddingTop: "20px" }}
//       >
//         Registration / पंजीकरण
//       </h1>
//       <label>
//         Name / आपका नाम :
//         <input
//           type="text"
//           name="Name"
//           // value={formData.name}
//           // onChange={handleChange}
//           placeholder="Name"
//         />
//       </label>

//       <label>
//         Email Address / मेल पता :
//         <input
//           type="email"
//           name="Email"
//           // value={formData.email}
//           // onChange={handleChange}
//           placeholder="Email Address"
//         />
//       </label>

//       <label>
//         Phone Number / फ़ोन नंबर:
//         <input
//           type="tel"
//           name="PhoneNum"
//           // value={formData.phone}
//           // onChange={handleChange}
//           placeholder="Phone Number"
//         />
//       </label>

//       <label>
//         Name of your Enterprise / आपके उद्यम का नाम :
//         <input
//           type="text"
//           name="Ename"
//           // value={formData.enterpriseName}
//           // onChange={handleChange}
//           placeholder="Name of enterprise"
//         />
//       </label>

//       <label>
//         Business Address / व्यावसायिक पता :
//         <input
//           type="text"
//           name="BusinessAdd"
//           // value={formData.city}
//           // onChange={handleChange}
//           placeholder="City"
//         />
//       </label>

//       <label>
//         Business you are involved in? / आप किस व्यवसाय से जुड़े हैं?
//         <input
//           type="text"
//           name="BusinessInvolved"
//           // value={formData.sellingCategory}
//           // onChange={handleChange}
//           placeholder="Selling Category"
//         />
//       </label>
//       <div className="btnForm">
//         <Button variant="contained" sx={{ color: "white" }} type="submit">
//           Submit
//         </Button>
//       </div>
//     </form>
//   );
// };

// export default SellOnONDCForm;

import React, { useState } from "react";
import "./SellOnONDCForn.css";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
const SellOnONDCForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNum: "",
    eName: "",
    businessAdd: "",
    businessInvolved: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://udyamwell-backend-prod.azurewebsites.net/ondc-seller/add-seller",
        formData
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          Swal.fire("Thankyou for Submitting !");
        } else {
          Swal.fire("Request not processed, please try again !");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // console.log("Form submitted:", formData);
  };

  return (
    <div className="ondc-form">
      <div className="heading">
        <h1 className="Text_Ondch1" style={{ width: "100%" }}>
          Opening <span style={{ color: "black" }}>up a </span> new{" "}
          <span style={{ color: "black" }}>world!</span>
        </h1>
        <p style={{ marginTop: "4px" }}>Start Selling on ONDC with UdyamWell</p>
      </div>
      <form className="formONDC" onSubmit={handleSubmit}>
        <h1
          className="section_tittle text-center mt-5 mb-4"
          style={{
            color: "#046A3A",
            fontFamily: "Poppins,sans-serif",
            fontWeight: "00",
            fontSize: "32px",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          Registration / पंजीकरण
        </h1>
        <div className="inputs">
          <label className="label2">
            Name / आपका नाम :
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
            />
          </label>

          <label className="label2">
            Email Address / मेल पता :
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
            />
          </label>

          <label className="label2">
            Phone Number / फ़ोन नंबर:
            <input
              type="tel"
              name="phoneNum"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone Number"
            />
          </label>

          <label className="label2">
            Name of your Enterprise / आपके उद्यम का नाम :
            <input
              type="text"
              name="eName"
              value={formData.enterpriseName}
              onChange={handleChange}
              placeholder="Name of enterprise"
            />
          </label>

          <label className="label2">
            Business Address / व्यावसायिक पता :
            <input
              type="text"
              name="businessAdd"
              value={formData.city}
              onChange={handleChange}
              placeholder="City"
            />
          </label>

          <label className="label2">
            Business you are involved in? / आप किस व्यवसाय से जुड़े हैं?
            <input
              type="text"
              name="businessInvolved"
              value={formData.sellingCategory}
              onChange={handleChange}
              placeholder="Selling Category"
            />
          </label>
        </div>
        <div className="btnForm">
          <Button
            variant="contained"
            sx={{
              color: "white",
              padding: "10px 60px",
              borderRadius: "10px",
              fontWeight: "400",
              textTransform: "inherit",
              border: "none",
              background:
                "linear-gradient(90deg, rgba(46,132,70,1) 0%, rgba(0,100,0,1) 100%);",
            }}
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SellOnONDCForm;
