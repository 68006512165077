// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Responses h2 {
    margin-top: 100px;
    color: #333;
  }
  
  
.Responses  table {
    margin-bottom: 10px;
    padding: 10px;
    filter: drop-shadow(1px 4px 4px #00000087);
    background-color: #ffffff;
    border-radius: 10px;
  }
  .btn{
    margin-top: 10px;
    padding-inline: 20px;
    padding-block: 10px;
    border-radius: 20px;
    color: black;
    background-color: #ffffff;
 
    font-size: 16px;
  }
  .btn:hover{
    cursor: pointer;
    color: white;
    background-color: green;
  }`, "",{"version":3,"sources":["webpack://./src/components/ResponsePage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;EACb;;;AAGF;IACI,mBAAmB;IACnB,aAAa;IACb,0CAA0C;IAC1C,yBAAyB;IACzB,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;;IAEzB,eAAe;EACjB;EACA;IACE,eAAe;IACf,YAAY;IACZ,uBAAuB;EACzB","sourcesContent":[".Responses h2 {\n    margin-top: 100px;\n    color: #333;\n  }\n  \n  \n.Responses  table {\n    margin-bottom: 10px;\n    padding: 10px;\n    filter: drop-shadow(1px 4px 4px #00000087);\n    background-color: #ffffff;\n    border-radius: 10px;\n  }\n  .btn{\n    margin-top: 10px;\n    padding-inline: 20px;\n    padding-block: 10px;\n    border-radius: 20px;\n    color: black;\n    background-color: #ffffff;\n \n    font-size: 16px;\n  }\n  .btn:hover{\n    cursor: pointer;\n    color: white;\n    background-color: green;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
