import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Modal,
  Box,
} from "@mui/material";

const CustomTable2 = ({
  heading,
  heading2,
  columns,
  data,
  style,
  handleButtonClick,
}) => {
  const [showAllRows, setShowAllRows] = useState(false);

  const toggleShowAllRows = () => {
    setShowAllRows(!showAllRows);
  };

  return (
    <div>
      <TableContainer style={style}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={columns.length}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "0px",
                  paddingBottom: "4px",
                  paddingInline: "10px",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Poppins, sans-serif",
                    textAlign: "center",
                  }}
                >
                  {heading} <span style={{ color: "#046A3A" }}>{heading2}</span>
                </div>
                <Button
                  sx={{
                    p: "4px 20px",
                    height: "90%",
                    borderRadius: "10px",
                    border: "solid 1.5px",
                    font: "Poppins,sans-serif",
                    fontWeight: "500",
                    color: "#006400",
                    textTransform: "initial",
                    transition:
                      "filter 0.4s ease, color 0.4s ease, background 0.3s ease",
                    "&:hover": {
                      filter: "drop-shadow(-2px 4px 4px   #0064003a  )",
                      color: "white",
                      background: "linear-gradient( #2E8446 , #006400 )",
                    },
                  }}
                  onClick={toggleShowAllRows}
                >
                  View All
                </Button>
              </TableCell>
            </TableRow>
            <TableRow
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "70px",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    fontFamily: "poppins",
                    textAlign: "center",
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(0, 2).map((row, rowIndex) => (
              <TableRow
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "70px",
                  justifyContent: "space-between",
                }}
                key={rowIndex}
              >
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>
                    <p style={{ width: "100%", fontSize: "16px" }}>{cell}</p>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={showAllRows} onClose={toggleShowAllRows}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            maxHeight: "80vh",
            width: "60vw",
            "@media (max-width: 900px)": {
              width: "80vw",
            },
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "70px",
                    justifyContent: "space-between",
                  }}
                >
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        fontFamily: "poppins",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, rowIndex) => (
                  <TableRow
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "70px",
                      justifyContent: "space-between",
                    }}
                    key={rowIndex}
                  >
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>
                        <p style={{ width: "100%", fontSize: "16px" }}>
                          {cell}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomTable2;
