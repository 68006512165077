import React from "react";
import { Box } from "@mui/material";
function VisitorsPage() {
  return (
    <>
      <Box></Box>
      <Box></Box>
      <Box></Box>
    </>
  );
}

export default VisitorsPage;
