import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import Bg_course from "../assets/Bg_course.webp";
// import article2 from "../assets/article2.webp";
// import article1 from "../assets/article1.webp";
import story from "../assets/storydummy.png";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Blog = () => {
  // Sample data for Women Empowerment cards
  const womenEmpowermentData = [
    {
      id: 1,
      image: story,
      date: "October 25, 2024",
      heading: "Empowering Women Through Education",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut lorem id dolor fringilla fermentum.",
      likes: 120,
      views: 1000,
    },
    {
      id: 2,
      image: story,
      date: "November 5, 2024",
      heading: "Women in Tech: Breaking the Glass Ceiling",
      description:
        "Sed vehicula ante a libero malesuada, non vestibulum lorem volutpat. Sed hendrerit orci in ligula tempor faucibus.",
      likes: 120,
      views: 1000,
    },
    {
      id: 3,
      image: story,
      date: "November 15, 2024",
      heading: "Female Entrepreneurs Making Waves",
      description:
        "Donec fringilla justo eget orci fermentum, sit amet fermentum magna malesuada. Nam id ex nec est fringilla varius.",
      likes: 120,
      views: 1000,
    },
  ];

  // Sample data for Success Stories cards
  const successStoriesData = [
    {
      id: 1,
      image: story,
      date: "December 5, 2024",
      heading: "From Rags to Riches: A True Inspirational Journey",
      description:
        "Pellentesque sed magna at sem placerat lacinia et at sem. Duis auctor leo non lorem interdum malesuada.",
      likes: 120,
      views: 1000,
    },
    {
      id: 2,
      image: story,
      date: "December 15, 2024",
      heading: "Overcoming Adversity: The Story of a Survivor",
      description:
        "Nullam fringilla, ligula nec sollicitudin molestie, sapien metus tincidunt nisi, at fermentum est est vel metus.",
      likes: 120,
      views: 1000,
    },
    {
      id: 3,
      image: story,
      date: "December 25, 2024",
      heading: "Turning Dreams into Reality: A Journey of Persistence",
      description:
        "Curabitur a ligula in nunc laoreet feugiat. In vitae mauris eu mi lobortis molestie. Proin vitae justo vel leo vehicula sollicitudin vitae ac nisi.",
      likes: 120,
      views: 1000,
    },
    {
      id: 4,
      image: story,
      date: "November 15, 2024",
      heading: "Female Entrepreneurs Making Waves",
      description:
        "Donec fringilla justo eget orci fermentum, sit amet fermentum magna malesuada. Nam id ex nec est fringilla varius.",
      likes: 120,
      views: 1000,
    },
  ];

  const renderCards = (data) => {
    return data.map((card) => (
      <Box
        key={card.id}
        sx={{
          width: 300,
          margin: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <img
          src={card.image}
          alt={card.heading}
          style={{
            width: "100%",
            height: 200,
            objectFit: "cover",
            padding: "10px 10px 10px 10px",
          }}
        />
        <Box p={2}>
          <Typography
            variant="subtitle2"
            sx={{ color: "#666", marginBottom: "4px" }}
          >
            {card.date}
          </Typography>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            {card.heading}
          </Typography>
          <Typography variant="body2">{card.description}</Typography>
        </Box>
        <Box
          p={2}
          borderTop="1px solid #eee"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row">
              <FavoriteIcon sx={{ color: "black", marginRight: "5px" }} />
              <Typography
                variant="body2"
                sx={{ color: "#666", marginRight: "10px" }}
              >
                {card.likes}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <VisibilityIcon sx={{ color: "black", marginRight: "5px" }} />
              <Typography variant="body2" sx={{ color: "#666" }}>
                {card.views}
              </Typography>
            </Box>
          </Box>
          <button
            style={{
              padding: "8px 16px",
              background: "#046A3A",
              color: "#fff",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Read More
          </button>
        </Box>
      </Box>
    ));
  };
  const renderCards2 = (data) => {
    return data.map((card) => (
      <Box
        key={card.id}
        sx={{
          width: 360,
          margin: "10px",

          borderRadius: "8px",
          overflow: "hidden",
          background: "#E6F0E8",
        }}
      >
        <img
          src={card.image}
          alt={card.heading}
          style={{
            width: "100%",
            height: 200,
            objectFit: "cover",
            padding: "10px 10px 10px 10px",
          }}
        />
        <Box p={2}>
          <Typography
            variant="subtitle2"
            sx={{ color: "#666", marginBottom: "4px" }}
          >
            {card.date}
          </Typography>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            {card.heading}
          </Typography>
          <Typography variant="body2">{card.description}</Typography>
        </Box>
        <Box
          p={2}
          borderTop="1px solid #eee"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="row">
            <FavoriteIcon sx={{ color: "black", marginRight: "5px" }} />
            <Typography
              variant="body2"
              sx={{ color: "#666", marginRight: "10px" }}
            >
              {card.likes}
            </Typography>
            <VisibilityIcon sx={{ color: "black", marginRight: "5px" }} />
            <Typography variant="body2" sx={{ color: "#666" }}>
              {card.views}
            </Typography>
          </Box>
          <button
            style={{
              padding: "8px 16px",
              background: "#046A3A",
              color: "#fff",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Read More
          </button>
        </Box>
      </Box>
    ));
  };
  const rightBoxesData = useMemo(
    () => [
      {
        id: 1,
        image: story,
        heading:
          "देश में अनुसंधान और नवाचार के मामले हिमाचल में सबसे आगे : गोकुल बुटेल",
        description: "This is the first article description.",
        date: "October 15, 2024",
      },
      {
        id: 2,
        image: story,
        heading: "How The Country's Largest Bank Is Supporting Social Startups",
        description: "This is the second article description.",
        date: "October 20, 2024",
      },
      {
        id: 3,
        image: story,
        heading: "How The Country's Largest Bank Is Supporting Social Startups",
        description: "This is the second article description.",
        date: "October 20, 2024",
      },
    ],
    []
  );

  const [selectedBoxIndex, setSelectedBoxIndex] = useState(0);
  const [selectedBox, setSelectedBox] = useState(
    rightBoxesData[selectedBoxIndex]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedBoxIndex(
        (prevIndex) => (prevIndex + 1) % rightBoxesData.length
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [rightBoxesData]);

  useEffect(() => {
    setSelectedBox(rightBoxesData[selectedBoxIndex]);
  }, [selectedBoxIndex, rightBoxesData]);

  return (
    <>
      <div className="landingPageCover">
        <img
          src={Bg_course}
          style={{ width: "100%", height: "960px" }}
          alt="landing"
          className="landingPageCover__animation"
        />
      </div>
      <Box sx={{ margin: "9rem auto 0 auto" }}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            width: "60%",
            m: "0 auto",
            mb: "20px",
            fontWeight: "600",
            fontSize: "50px",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          Connecting Rural Dreams Success Stories That Inspire
        </Typography>
        <hr
          style={{
            height: "2px",
            background: "#046A3A",
            border: "none",
            margin: "0 auto 20px auto",
            width: "76%",
          }}
        />
      </Box>

      {/* Section for TwoBoxes */}
      <div
        className="two-boxes-container"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "80%",
          margin: "0px auto",
        }}
      >
        <div className="selected-box">
          <img
            style={{ width: "500px", height: "400px" }}
            src={selectedBox.image}
            alt=""
          />
          <p style={{ fontWeight: "500", color: "grey" }}>{selectedBox.date}</p>
          <h2 style={{ fontWeight: "600", color: "black", lineHeight: "36px" }}>
            {selectedBox.heading}
          </h2>
          <p style={{ fontWeight: "500", color: "black", fontSize: "18px" }}>
            {selectedBox.description}
          </p>
        </div>

        <div className="right-boxes">
          {rightBoxesData.map((box, index) => (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              key={box.id}
              className={`right-box ${
                index === selectedBoxIndex ? "active" : ""
              }`}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <img
                  src={box.image}
                  style={{ width: "100px", height: "100px" }}
                  alt=""
                />
                <div>
                  <p style={{ fontWeight: "500", color: "grey" }}>{box.date}</p>
                  <h3 style={{ fontSize: "18px" }}>{box.heading}</h3>
                  <p>{box.description}</p>
                </div>
              </div>
              <hr
                style={{
                  height: "3px",
                  background: "#046A3A",
                  border: "none",
                  margin: "20px auto",
                  width: "100%",
                }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Section for Women Empowerment */}
      <Box sx={{ margin: "9rem auto 0 auto" }}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "left",
            width: "80%",
            m: "0 auto",
            fontWeight: "600",
            fontSize: "50px",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          <span style={{ color: "#047A43" }}>Women's </span> Empowerment
        </Typography>
        <p
          style={{
            textAlign: "left",
            width: "50%",
            margin: "0px auto 0px 150px",
            fontSize: "18px",
            color: "black",
            marginTop: "20px",
          }}
        >
          Focus on programs and projects that empower women in rural
          communities, such as self-help groups and entrepreneurship
          opportunities.
        </p>

        {/* Row of cards for Women Empowerment */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            marginTop: "40px",
          }}
        >
          {renderCards2(womenEmpowermentData)}
        </Box>
      </Box>

      {/* Section for Success Stories */}
      <Box sx={{ margin: "9rem auto 0 auto" }}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            width: "80%",
            m: "0 auto",
            fontWeight: "600",
            fontSize: "50px",
            fontFamily: "Poppins,sans-serif",
          }}
        >
          <span style={{ color: "#047A43" }}>Success</span> Stories
        </Typography>
        <p
          style={{
            textAlign: "center",
            width: "80%",
            margin: "0 auto",
            fontSize: "18px",
            color: "black",
            marginTop: "20px",
          }}
        >
          A one-line description goes here.
        </p>

        {/* Row of cards for Success Stories */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            marginBottom: "100px",
            marginTop: "20px",
          }}
        >
          {renderCards(successStoriesData)}
        </Box>
      </Box>
    </>
  );
};

export default Blog;
