import React, { useEffect, useState } from "react";
import "./styles/register.css";
import "./TestimonialCard.css";
import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginCampuspreneur } from "../slices/UserSlice.js";
import { useTranslation } from "react-i18next";
import Bg_login from "../assets/Bg_login.webp";
import User0 from "../assets/Testinomials.webp";
import User1 from "../assets/Testinomials1.webp";
import User2 from "../assets/Testinomials2.webp";

const CampusLogin = () => {
  const { t } = useTranslation();
  const { user, error } = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    user && navigate("/");
  }, [user, navigate]);

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("Btn Hit");
    const values = {
      email,
      password,
    };
    try {
      await dispatch(loginCampuspreneur(values));
      navigate("/campusprenuerdashboard");
    } catch (error) {
      console.log("Error logging in:", error);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const testimonials = [
    {
      name: "Ajay",
      text: "उद्यमवेल का उद्यमशील भारत प्रोग्राम और उनका समर्थन मेरे लिए एक वरदान है। इससे मेरे व्यवसाय को बढ़ाने में बहुत मदद मिली है। उद्यमवेल से जुड़े हुए कुछ ही महीनों में मेरे आय के स्रोतों में वृद्धि हुई है। ",
      photo: User0,
    },
    {
      name: "Kalyani Keerti",
      text: "उद्यमवेल ने मुझे कैसे काम को सही तरीके से करके आगे बढ़ा जा सकता है ! उद्यमवेल में  जुड़ने के बाद से मेरा छोटा बिजनेस मुझे एक बड़े बिजनेस का आकार दिखा रहा है इस पूरी टीम को दिल से बहुत बहुत धन्यवाद। उद्यमवेल से मुझे जोड़ने के लिए सचिन जी बहुत-बहुत  धन्यवाद!",
      photo: User1,
    },
    {
      name: "Srinithi Sripelly",
      text: "UdyamWell's capacity-building program empowered our rural women entrepreneurs with valuable knowledge and skills. We are grateful for their dedication and look forward to a long-term partnership.",
      photo: User2,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [testimonials.length]);
  return (
    <>
      <div className="landingPageCover">
        <img
          src={Bg_login}
          width="100vw"
          alt="landing"
          className="landingPageCover__animation"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: "8rem",
        }}
      >
        {" "}
        <div className="loginContainer">
          <div className="login-form" data-aos="fade-left">
            <div className="mainForm">
              <form style={{ paddingBlock: "40px" }} onSubmit={handleLogin}>
                {error && (
                  <Alert
                    sx={{ fontSize: "15px", p: 0.3, mt: 2, mb: 0 }}
                    severity="error"
                  >
                    {error}
                  </Alert>
                )}
                <Stack
                  alignSelf={"center"}
                  sx={{ width: "90%", margin: "1rem auto" }}
                  spacing={4}
                >
                  <h1 className="campuslogin-head">
                    <span style={{ color: "#046A3A" }}>Campuspreneur </span>
                    Login
                  </h1>
                  <TextField
                    fullWidth
                    name={"email"}
                    type="text"
                    label={t("formph_mail")}
                    sx={{
                      backgroundColor: "#E0F1E5",
                      "& .MuiOutlinedInput-input::placeholder": {
                        color: "black",
                        fontWeight: "400",
                        alignSelf: "center",
                      },
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />

                  <Box>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        {t("formph_password")}
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        sx={{
                          backgroundColor: "#E0F1E5",
                          "& .MuiOutlinedInput-input::placeholder": {
                            color: "black", // Placeholder color
                            fontWeight: "400",
                            alignSelf: "center",
                          },
                        }}
                        label="Password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                    </FormControl>
                  </Box>
                </Stack>
                <Box style={{ textAlign: "center" }}>
                  <Typography sx={{ mt: 3 }}>
                    Don't have an Account?{" "}
                    <Link to="/campuspreneur-form">
                      <span style={{ color: "green", textDecoration: "none" }}>
                        Enroll for Campuspreneur Program
                      </span>
                    </Link>
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    Forgot Password?{" "}
                    <Link to="/forgot-password-campuspreneur">
                      <span style={{ color: "green", textDecoration: "none" }}>
                        Click here to reset your Password
                      </span>
                    </Link>
                  </Typography>
                  <div className="btns-2" style={{ alignItems: "flex-end" }}>
                    <Box sx={{ mt: 3 }}>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          color: "white",
                          padding: "10px 60px",
                          borderRadius: "10px",
                          fontWeight: "400",
                          textTransform: "inherit",
                          border: "none",
                          background:
                            "linear-gradient(90deg, rgba(46,132,70,1) 0%, rgba(0,100,0,1) 100%);",
                        }}
                      >
                        {t("submit_btn")}
                      </Button>
                    </Box>
                  </div>
                </Box>
              </form>
            </div>
          </div>

          <div className="testimonial-card2" data-aos="fade-right">
            {" "}
            <p className="testimonial-text">
              {testimonials[currentIndex].text}
            </p>
            <img src={testimonials[currentIndex].photo} alt="Testimonial" />
            <p className="testimonial-name">
              {testimonials[currentIndex].name}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampusLogin;
