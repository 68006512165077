import React from "react";

const CustomTable = ({ heading, columns, data, style, rank }) => {
  return (
    <div>
      <table style={style}>
        <thead>
          <tr>
            <th colSpan={columns.length} style={{ fontSize: "18px" }}>
              <span style={{ color: "green" }}>#{rank} </span>
              {heading}
            </th>
          </tr>
          <hr
            style={{
              width: "100%",
              margin: "20px auto",
              height: "1px",
              background: "grey",
            }}
          />
          <tr
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {columns.map((column, index) => (
              <th
                key={index}
                style={{
                  fontSize: "18px",
                  fontWeight: "normal",
                }}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
              key={rowIndex}
            >
              <td>{row.rank}</td>
              <td>{row.name}</td>
              <td>{row.totalMarks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
