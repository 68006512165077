import React from "react";

const VerifySucces = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "10rem auto",
        }}
      >
        <h1 style={{ color: "green" }}>Your email has been verified.</h1>
      </div>
    </>
  );
};

export default VerifySucces;
