import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Input,
} from "@mui/material";
import axios from "axios";

const CampusAdmin = () => {
  const [users, setUsers] = useState([]);
  // const [selectedUser, setSelectedUser] = useState(null);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openAssignModal2, setOpenAssignModal2] = useState(false);
  const [openTaskDetailsModal, setOpenTaskDetailsModal] = useState(false);
  // const [latestSubmission, setLatestSubmission] = useState(null);
  const [taskName, setTaskName] = useState("");
  const [taskID, setTaskID] = useState("");
  const [taskIdForSubmit, setTaskIdForSubmit] = useState("");
  const [userIdForSubmit, setUserIdForSubmit] = useState("");
  const [taskDetails, setTaskDetails] = useState(null);
  const [deadline, setDeadline] = useState("");
  const [taskMarks, setTaskMarks] = useState({});

  const handleMarksChange = (index, value) => {
    setTaskMarks((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleUpdateMarks = async (userId, marks, taskId) => {
    console.log("marks:", userId, marks, taskId);
    try {
      const response = await axios.patch(
        `https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/update-marks/${userId}`,
        {
          marks: marks,
          taskId: taskId,
        }
      );
      console.log("marks", response);
      alert("marks_updated");
      return response.data;
    } catch (error) {
      throw new Error(`Failed to update marks: ${error.message}`);
    }
  };

  // const handleApprovalChange = (event) => {
  //   setApproved(event.target.checked);
  // };

  useEffect(() => {
    axios
      .get(
        "https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/get-udyamsathi"
      )
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setUsers(response.data);
        } else {
          console.log("No data found for udyamsathi.");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const handleAssignTask = (userId, taskId) => {
    // const user = users.find((user) => user.userId === userId);
    // setSelectedUser(user);
    setOpenAssignModal(true);
    setTaskIdForSubmit(taskId);
    setUserIdForSubmit(userId);
  };
  const handleRemoveUser = async (userId) => {
    try {
      const response = await axios.delete(
        `https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/remove-udyamsathi/${userId}`
      );
      window.location.reload();

      if (response.status === 200) {
        console.log("User removed successfully!");

        alert("User Removed Successfully");
      } else {
        console.error("Something went wrong:", response.data.message);
      }
    } catch (error) {
      console.error("Internal Server Error:", error.message);
    }
  };

  const handleSubmitTask = () => {
    const taskData = {
      taskId: taskID,
      taskName: taskName,
      deadline: deadline,
      _id: taskIdForSubmit,
      userId: userIdForSubmit,
    };
    // Make the PUT request
    axios
      .put(
        "https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/add-task",
        taskData
      )
      .then((response) => {
        console.log("Task added successfully:", response.data);
        alert("task added successfully");
        // Reset input fields and close the modal
        setOpenAssignModal(false);
        setTaskName("");
        setTaskID("");
        setDeadline("");
      })
      .catch((error) => {
        console.error("Error adding task:", error);
      });
  };

  const fetchPdf = async (taskId, _id) => {
    console.log("Fetch pdf hit ==>");
    try {
      const response = await fetch(
        `https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/get-file/${taskId}/${_id}`
      );
      if (response.ok) {
        const blob = await response.blob();
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl); // Open PDF in a new tab
      } else {
        console.error("Failed to fetch PDF:", response.status);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  const handleViewTask = async (userId) => {
    console.log(userId);

    try {
      const taskResponse = await axios.get(
        `https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/get-task-by-id/${userId}`
      );

      console.log("data:", taskResponse.data);
      console.log("task:", taskResponse.data.task);

      const taskDetails = [];

      if (
        taskResponse.data &&
        taskResponse.data.task &&
        Array.isArray(taskResponse.data.task) &&
        taskResponse.data.task.length > 0
      ) {
        taskResponse.data.task.forEach((task) => {
          const { taskId, submissions } = task;

          let latestSubmission = "No submission yet";
          let _id = ""; // Declare _id as let variable

          if (submissions && submissions.length > 0) {
            submissions.forEach((submission) => {
              _id = submission ? submission.udyamsathi : null;
            });
          }

          // Create task detail object with taskId and either View button or "No submission yet" message
          const taskDetail = [
            taskId,
            _id ? ( // Check if userId is not null
              <Button
                sx={{
                  p: "4px 20px",
                  height: "90%",
                  borderRadius: "10px",
                  border: "solid 1.5px",
                  font: "Poppins,sans-serif",
                  fontWeight: "500",
                  color: "#006400",
                  textTransform: "initial",
                  transition:
                    "filter 0.4s ease, color 0.4s ease, background 0.3s ease",
                  "&:hover": {
                    filter: "drop-shadow(-2px 4px 4px   #0064003a  )",
                    color: "white",
                    background: "linear-gradient( #2E8446 , #006400 )",
                  },
                }}
                onClick={() => fetchPdf(taskId, _id)}
              >
                View
              </Button>
            ) : (
              <span>{latestSubmission}</span>
            ),
            _id,
          ];

          taskDetails.push(taskDetail);
        });
      } else {
        console.log("No tasks found for the provided user ID.");
      }

      console.log("Task details:", taskDetails);
      setTaskDetails(taskDetails);
      setOpenTaskDetailsModal(true);
    } catch (error) {
      console.error("Error fetching task details:", error);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNum: "",
    userId: "",
  });

  const handleChangeCampusPreneur = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCreateCampusPreneur = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/create-udyamsathi",
        formData
      );
      console.log("Campuspreneur created:", response.data);
      alert("Created Successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error creating Campuspreneur:", error);
      alert("Error creating Campuspreneur");
    }
  };
  const handleAssignTaskToAll = () => {
    setOpenAssignModal2(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal2(false);
  };
  const handleSubmitTaskToAll = () => {
    const taskData = {
      taskId: taskID,
      taskName: taskName,
      deadline: deadline,
      marks: "10",
    };

    axios
      .put(
        "https://udyamwell-backend-prod.azurewebsites.net/udyamsathi/add-task",
        taskData
      )
      .then((response) => {
        console.log("Task added successfully to all users:", response.data);
        alert("Task added successfully to all users");
        setOpenAssignModal(false);
        setTaskName("");
        setTaskID("");
        setDeadline("");
      })
      .catch((error) => {
        console.error("Error adding task to all users:", error);
      });
  };

  return (
    <div style={{ width: "90%", margin: "8rem auto" }}>
      <Button
        variant="contained"
        style={{ marginBottom: "40px" }}
        onClick={() => handleAssignTaskToAll()}
      >
        Assign Task To All
      </Button>
      <Paper elevation={4}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">Assign Task</TableCell>
                <TableCell align="center">View</TableCell>
                <TableCell align="center">Remarks</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length > 0 ? (
                users.map((user) => (
                  <TableRow key={user.userId}>
                    <TableCell align="center">{user.name}</TableCell>
                    <TableCell align="center">{user.userId}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        onClick={() => handleAssignTask(user.userId, user._id)}
                      >
                        Assign Task
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        onClick={() => handleViewTask(user._id)}
                      >
                        View Task
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <TextField />
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => handleRemoveUser(user.userId, user._id)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No users exist
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* Modal for assigning task */}
      <Modal
        open={openAssignModal}
        onClose={() => setOpenAssignModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Task Name"
              variant="outlined"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Task ID"
              variant="outlined"
              value={taskID}
              onChange={(e) => setTaskID(e.target.value)}
            />
            <TextField
              fullWidth
              label="Deadline"
              type="date"
              variant="outlined"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
            />

            <Button onClick={handleSubmitTask} variant="contained">
              Submit
            </Button>
          </Stack>
        </Paper>
      </Modal>
      {/**/}

      <Modal
        open={openTaskDetailsModal}
        onClose={() => setOpenTaskDetailsModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxHeight: 400,
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack spacing={2}>
            {taskDetails ? (
              taskDetails.map((taskDetail, index) => (
                <div key={index}>
                  <Typography variant="h1" style={{ fontSize: "18px" }}>
                    Task {index + 1}
                  </Typography>
                  <div>
                    <div>
                      <strong>Task ID: </strong> {taskDetail[0]}{" "}
                      {/* Access task ID */}
                    </div>
                    <div>
                      <strong>Task File: </strong> {taskDetail[1]}{" "}
                      {/* Access JSX element */}
                    </div>
                    <div>
                      <Typography gutterBottom>Marks</Typography>
                      <Input
                        type="number"
                        value={taskMarks[index] || ""}
                        onChange={(e) =>
                          handleMarksChange(index, e.target.value)
                        }
                        inputProps={{ min: 0 }}
                      />
                    </div>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        handleUpdateMarks(
                          taskDetail[2],
                          taskMarks[index] || 0,
                          taskDetail[0]
                        )
                      }
                    >
                      Update Marks
                    </Button>
                    {/* <div>
                      <Checkbox
                        checked={approved}
                        onChange={handleApprovalChange}
                        inputProps={{ "aria-label": "approved checkbox" }}
                      />
                      <Typography variant="body2">Approved</Typography>
                    </div> */}
                    <hr
                      style={{
                        width: "100%",
                        height: "4px",
                        background: "black",
                      }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <Typography>No task details available</Typography>
            )}
          </Stack>
        </Paper>
      </Modal>

      <Modal
        open={openAssignModal2}
        onClose={handleCloseAssignModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Task Name"
              variant="outlined"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Task ID"
              variant="outlined"
              value={taskID}
              onChange={(e) => setTaskID(e.target.value)}
            />
            <TextField
              fullWidth
              label="Deadline"
              type="date"
              variant="outlined"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
            />

            <Button onClick={handleSubmitTaskToAll} variant="contained">
              Submit task to all
            </Button>
          </Stack>
        </Paper>
      </Modal>
      <form onSubmit={handleCreateCampusPreneur} style={{ marginTop: "50px" }}>
        <h1>Create Campusprenuer</h1>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fields</TableCell>
                <TableCell>Enter Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  <TextField
                    name="name"
                    value={formData.name}
                    onChange={handleChangeCampusPreneur}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>
                  <TextField
                    name="email"
                    value={formData.email}
                    onChange={handleChangeCampusPreneur}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone Number</TableCell>
                <TableCell>
                  <TextField
                    name="phoneNum"
                    value={formData.phoneNum}
                    onChange={handleChangeCampusPreneur}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell>
                  <TextField
                    name="userId"
                    value={formData.userId}
                    onChange={handleChangeCampusPreneur}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button type="submit" variant="contained">
          Create Campuspreneur
        </Button>
      </form>
    </div>
  );
};

export default CampusAdmin;
