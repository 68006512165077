// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Carousel.css */
.container {
    /* position: relative; */
    padding-top: 140px;
    width: 100%;
    height: 500px; /* Adjust height as needed */
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    gap: 80px;
  }
  
  .card {
    width: 35%; /* Adjust card width as needed */
    height: 360px; /* Adjust card height as needed */
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.351); /* Add drop shadow */
    position: absolute;
    transition: transform 0.5s ease;
    margin-left: 22vw;
    
  }
  
  .card:hover {
    transform: scale(1.1);
    box-shadow: 0 20px 22px rgba(0, 0, 0, 0.578);
  }
  
  .card iframe {
    border-radius: 20px; /* Add border radius to iframe */
  }

@media (max-width: 1000px) {
    .card {
    margin-left: 10%;
}
}
@media (max-width: 700px) {
    .card {
        width: 35%; 
        margin-left: 15%;
}
}
@media (max-width: 500px) {
  .card {
    width: 50%; 
    margin-left: 16%;
    height: 300px;
}
.container {

  padding-top: 160px; }

}`, "",{"version":3,"sources":["webpack://./src/components/Carousel.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,wBAAwB;IACxB,kBAAkB;IAClB,WAAW;IACX,aAAa,EAAE,4BAA4B;IAC3C,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,SAAS;EACX;;EAEA;IACE,UAAU,EAAE,gCAAgC;IAC5C,aAAa,EAAE,iCAAiC;IAChD,yBAAyB;IACzB,sBAAsB;IACtB,mBAAmB;IACnB,4CAA4C,EAAE,oBAAoB;IAClE,kBAAkB;IAClB,+BAA+B;IAC/B,iBAAiB;;EAEnB;;EAEA;IACE,qBAAqB;IACrB,4CAA4C;EAC9C;;EAEA;IACE,mBAAmB,EAAE,gCAAgC;EACvD;;AAEF;IACI;IACA,gBAAgB;AACpB;AACA;AACA;IACI;QACI,UAAU;QACV,gBAAgB;AACxB;AACA;AACA;EACE;IACE,UAAU;IACV,gBAAgB;IAChB,aAAa;AACjB;AACA;;EAEE,kBAAkB,EAAE;;AAEtB","sourcesContent":["/* Carousel.css */\n.container {\n    /* position: relative; */\n    padding-top: 140px;\n    width: 100%;\n    height: 500px; /* Adjust height as needed */\n    display: flex;\n    flex-direction: row;\n    margin: 0 auto;\n    gap: 80px;\n  }\n  \n  .card {\n    width: 35%; /* Adjust card width as needed */\n    height: 360px; /* Adjust card height as needed */\n    background-color: #f0f0f0;\n    border: 1px solid #ccc;\n    border-radius: 20px;\n    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.351); /* Add drop shadow */\n    position: absolute;\n    transition: transform 0.5s ease;\n    margin-left: 22vw;\n    \n  }\n  \n  .card:hover {\n    transform: scale(1.1);\n    box-shadow: 0 20px 22px rgba(0, 0, 0, 0.578);\n  }\n  \n  .card iframe {\n    border-radius: 20px; /* Add border radius to iframe */\n  }\n\n@media (max-width: 1000px) {\n    .card {\n    margin-left: 10%;\n}\n}\n@media (max-width: 700px) {\n    .card {\n        width: 35%; \n        margin-left: 15%;\n}\n}\n@media (max-width: 500px) {\n  .card {\n    width: 50%; \n    margin-left: 16%;\n    height: 300px;\n}\n.container {\n\n  padding-top: 160px; }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
